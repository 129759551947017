import React from "react";
import {TenantInterface} from "../Interface/TenantInterface";
import {TenantModel} from "../Model/TenantModel";

export interface AppContextValueInterface {
    state: {
        tenant: TenantInterface
    },
    func: {}
}

export const defaultAppContextValue: AppContextValueInterface = {
    state: {
        tenant: TenantModel
    },
    func: {}
}

const AppContext = React.createContext<AppContextValueInterface>({...defaultAppContextValue});

export {AppContext};
