import React, {useEffect, useRef, useState} from "react";
import {trans} from "../../resources/localization/Localization";
import DefaultLayout from "../../Component/Layout/Default";
import PackageOrderFilter from "./Filter";
import PackageOrderList from "./List";
import {PackageOrderInterface} from "../../Interface/PackageOrderInterface";
import {useSearchParams} from "react-router-dom";
import {PackageOrderUpdateStatusCommand} from "../../Criteria/PackageOrdersCriteria";
import {PackageOrderApi} from "../../Api/PackageOrderApi";
import lodash from "lodash";
import {message, notification,} from "antd";
import {PackageVendorInterface} from "../../Interface/PackageVendorInterface";
import './style.scss'
import EntryPopup from "./components/EntryPopup";

const PACKAGE_ORDER_STATUS = [
    {code: 'PENDING', name: 'packageOrder.pending-status', color: '#FFA634'},
    {code: 'PAID', name: 'packageOrder.paid-status', color: '#09B8AF'},
    {code: 'REJECTED', name: 'packageOrder.rejected-status', color: '#FF4559'},
    {code: 'WAITING', name: 'packageOrder.waiting-status', color: '#7D7E7E'},
]

type PackageOrderProps = {
    children?: any;
}

function PackageOrders(props: PackageOrderProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [packageOrders, setPackageOrders] = useState<PackageOrderInterface[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [changedStatus, setChangedStatus] = useState<boolean>(false);
    const [pagination, setPagination] = React.useState<any>({});
    const [filter, setFilter] = useState<any>();
    const [vendors, setVendors] = useState<PackageVendorInterface[]>([]);
    const [statusUpdateData, setStatusUpdateData] = useState<PackageOrderUpdateStatusCommand>({
        status: '',
        ref: '',
    })
    const [showEntryPopup, setShowEntryPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState<PackageOrderInterface>();


    useEffect(() => {
        const getVendors = () => {
            PackageOrderApi.getVendors().then((response) => {
                if (response.data) {
                    let activeVendors = response.data.filter((d: any) => d.status === 'ACTIVE');
                    // console.log(activeVendors)
                    setVendors(activeVendors);
                }
            }).catch((err) => {
            })
        }
        getVendors();
    }, []);

    const getPackageOrders = (filter?: any) => {
        setLoading(true)
        filter = {
            ...filter,
            sort: 'createdAt',
            order: 'DESC'
        }
        setFilter(filter)
        PackageOrderApi.getPackageOrders(filter).then((response) => {
            let currentPage = lodash.get(response, 'headers.x-page-number');
            let total = lodash.get(response, 'headers.x-total-count');
            setPackageOrders(response.data);
            setPagination({
                current: parseInt(currentPage) + 1,
                hideOnSinglePage: true,
                total: parseInt(total),
                pageSize: parseInt(lodash.get(response, 'headers.x-page-size'))
            })
        })
            .catch((err) => {
                console.log(err.response)
                setPackageOrders([])
                setPagination({
                    current: 1,
                    hideOnSinglePage: true,
                    total: 0,
                    pageSize: 25
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getPackageOrders(Object.fromEntries(searchParams.entries()))
    }, [searchParams, changedStatus])

    const handleChangePage = (page: number, pageSize: number) => {
        const newFilter = {
            ...filter,
            page: (page - 1).toString(),
            size: pageSize.toString(),
        }

        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            ...newFilter
        })
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleChangePackageOrderStatus = (packageCode: string, status: string, selectedItem: PackageOrderInterface) => {
        setSelectedItem(selectedItem);
        if (status !== 'PAID') {
            handleUpdatePackageOrderStatus(packageCode, status);
        } else {
            setShowEntryPopup(true);
        }
    }

    const handleCancelEntryPopup = () => {
        setShowEntryPopup(false);
    }

    const handleUpdatePackageOrderStatus = (packageOrderCode: string, status?: string,ref?:string) => {
        setLoading(true);
        PackageOrderApi.updatePackageOrderStatus(packageOrderCode, {status:status!,ref:ref}).then(() => {
            message.success(trans('packageOrder.update-status-success')).then(r => {
            });
            setChangedStatus(!changedStatus);
        }).catch((err) => {
            if(err.response.data.title === 'ref_exist') {
                notification.error({
                    message:trans('packageOrder.update-status-failed'),
                    description:trans('packageOrder.ref_key_existed')
                })
            } else
            message.error(trans('packageOrder.update-status-failed'), 2).then(r =>{})
        }).finally(() => {
            setLoading(false);
            setShowEntryPopup(false);
        })
    }

    const handleUpdateOrderPromoteStatus = (packageOrdCode: string, action: string) => {
        let bodyData = {
            promoteStatus: action,
        }
        setLoading(true);
        PackageOrderApi.updatePromoteStatus(packageOrdCode, bodyData).then(() => {
            message.success(trans('packageOrder.update-promote-success')).then(r => {
            });
            setChangedStatus(!changedStatus);
        }).catch(() => {
            message.error(trans('packageOrder.update-status-failed'), 2).then(r => {
            })
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <DefaultLayout title={trans('packageOrders.list')} {...props}>
            <PackageOrderFilter
                statuses={PACKAGE_ORDER_STATUS}
                vendors={vendors}
            />
            <PackageOrderList
                pagination={pagination}
                handleChangePage={handleChangePage}
                isLoading={loading}
                packageOrders={packageOrders}
                statuses={PACKAGE_ORDER_STATUS}
                handleUpdatePackageOrderStatus={handleChangePackageOrderStatus}
                vendors={vendors}
                getPackageOrders={(newFilter?: any) => getPackageOrders(newFilter || filter)}
                handleUpdateOrderPromoteStatus={handleUpdateOrderPromoteStatus}
                filter={filter}
            />
            <EntryPopup
                handleSubmit={handleUpdatePackageOrderStatus}
                handleCancel={handleCancelEntryPopup}
                setUpdateStatusData={setStatusUpdateData}
                updateStatusData={statusUpdateData}
                showEntryPopup={showEntryPopup}
                selectedItem={selectedItem}
            />
        </DefaultLayout>
    )
}

export default PackageOrders;
