import React, { useContext, useEffect } from "react"
import { Spin } from "antd"
import { AppContext, AppContextValueInterface } from "../../Context/AppContext"
import { useSearchParams } from "react-router-dom"
import localStore from "../../Util/LocalStore"
import appConfig from "../../Config/App"

export const Login = () => {
	const appValue = useContext<AppContextValueInterface>(AppContext)

	const [searchParams] = useSearchParams()

	useEffect(() => {
		localStore.setItem("redirectBackUrl", searchParams.get("redirectBackUrl") || btoa("/apps"))

		setTimeout(() => {
			const tenant = appValue.state.tenant
			// @ts-ignore
			window.location.href =
				appConfig.loginUrl + "/" + tenant.code + "?redirect_uri=" + appConfig.authenticationUrl
		}, 1000)
	})

	return (
		<div className={"mg-t-10 mg-l-10"}>
			Đang chuyển trang, vui lòng đợi một chút.... <Spin />
		</div>
	)
}
