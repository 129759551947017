export enum PackageStatusEnum {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING', // treo
}

export enum BillTypeEnum {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
}

export enum RequestBillFormNames {
  billType = 'type',
  taxCode = 'tax',
  companyName = 'companyName',
  companyAddress = 'companyAddress',
  companyEmail = 'companyEmail',

  fullName = 'fullName',
  address = 'address',
  email = 'email',
}

export enum RequestBillStatus {
  WAITING = 'PENDING',
  SUCCESS = 'SIGNED',
}

export interface PaymentInvoiceInterface {
  id: string;
  tenant: string;
  order: string;
  name: string;
  address: string;
  email: string;
  tax: string;
  owner: string;
  refPayment: string;
  status: string;
  type: BillTypeEnum;
  invoice: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
}

export interface PackageOrderInterface {
  id: string;
  tenant: string;
  code: string;
  customer: string;
  vendor: string;
  packageCode: string;
  originalPrice: number;
  currency: string;
  status: string | PackageStatusEnum;
  totalValue: number;
  packageName: string;
  paymentMethod: string;
  billOrder: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  paymentInvoice?: PaymentInvoiceInterface;
}

export const PackageOrderDefaultModel: PackageOrderInterface = {
  billOrder: '',
  code: '',
  createdAt: '',
  createdBy: '',
  currency: '',
  customer: '',
  id: '',
  modifiedAt: '',
  modifiedBy: '',
  originalPrice: 0,
  packageCode: '',
  packageName: '',
  paymentMethod: '',
  status: '',
  tenant: '',
  totalValue: 0,
  vendor: '',
  version: 0,
};

export interface InvoiceData {
  code: string;
  invoiceId?: string;
  owner?: string;
}

export interface CreatePaymentInvoiceBody {
  order?: string;
  name: string;
  address: string;
  email: string;
  tax?: string;
  owner?: string;
  type: BillTypeEnum;
}

export const paymentStatusKeyMapping: any = {
  [PackageStatusEnum.PENDING]:  'packageOrder.pending-status',
  [PackageStatusEnum.PAID]:  'packageOrder.paid-status',
  [PackageStatusEnum.REJECTED]:  'packageOrder.rejected-status',
  [PackageStatusEnum.WAITING]:  'packageOrder.waiting-status',
}

export enum PaymentLogTypeEnum {
  NEW_OBJECT = 'NEW_OBJECT',
  PROPERTY_CHANGE = 'PROPERTY_CHANGE',
}

export interface PaymentLogInterface {
  id: string
  application: string
  namespace: string
  module: string
  tenant: string
  subTenant: string
  activity: 'UPDATE_ORDER' | 'CREATE_ORDER' | 'UPDATE_INVOICE' | 'CREATE_INVOICE'
  timestamp: string;
  actor: {
    grn: string // 'dota:order_payment:broodmother:vn::::admin';
    username:  string
    namespace: string
    module: string
    role: string
    tenant: string
    ip: string
    agent: string
    metadatda: null;
  };
  tracing: null;
  change: {
    type: PaymentLogTypeEnum;
    newObject?: PaymentInvoiceInterface[];
    removedObject: [];
    valueChange: {
      changedValues: [
        {
          key: 'status';
          left: 'PENDING';
          right: PackageStatusEnum;
        }
      ];
    };
    mapChange: null;
    containerChange: null;
  };
  target: {
    id: string
    grn: string
    metadata: any | PaymentInvoiceInterface | PackageOrderInterface
  };
}
