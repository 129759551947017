import React, { useEffect, useState } from 'react'
import lodash from 'lodash'
import { Col, Row, Table } from 'antd'
import { formatDateTime, formatMoneyByUnit } from '../../../Util/string'
import { trans } from '../../../resources/localization/Localization'
import TransactionInterface from '../../../Interface/TransactionInterface'
import EmptyData from '../../../Component/EmptyData'
interface TransactionProps {
	loading: boolean
	items: TransactionInterface[]
}
function Transactions(props: TransactionProps) {
	const { items, loading } = props
	const formatAmount = (amount: any, currency: string) => {
		let color = amount < 0 ? 'text-red' : 'text-green'
		return <span className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
	}
	const columns = [
		{
			title: <span className='text-nowrap'>{trans('orderDetail.transactionTime')}</span>,
			dataIndex: 'timestamp',
			key: 'timestamp',
			render: (text: string, record: any) => <span className={''}>{formatDateTime(record.timestamp)}</span>,
		},

		{
			title: <span className='text-nowrap'>{trans('orderDetail.transactionAmount')}</span>,
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string, record: any) => formatAmount(record.amount, record.currency),
		},
		{
			title: <span className='text-nowrap'>{trans('orderDetail.transactionType')}</span>,
			dataIndex: 'type',
			key: 'type',
			render: (text: string, record: any) => <span>{lodash.get(record, 'type.name', '--')}</span>,
		},
		{
			title: <span className='text-nowrap'>{trans('orderDetail.transactionMemo')}</span>,
			dataIndex: 'memo',
			key: 'memo',
			render: (text: string, record: any) => (
				<div className='flex flex-col'>
					<span className='flex items-center gap-6'>
						<span className='text-reg text-gray text-nowrap'>{`${trans('order.code')}:`}</span>
						<span className='text-reg text-gray text-nowrap'>{record.txid ? record.txid : '---'}</span>
					</span>
					<span className='text-reg text-nowrap'>{record.memo ? record.memo : '---'}</span>
				</div>
			),
		},
	]
	return (
		<Table
			columns={columns}
			dataSource={items}
			locale={{ emptyText:  <EmptyData text={trans('orders.empty')} /> }}
			className='main-table main-table_middle'
			pagination={{
				total: items.length,
				pageSize: 5,
				position: ['bottomRight'],
				locale: { items_per_page: `/ ${trans('orders.page')}` },
				hideOnSinglePage: true,
				showSizeChanger: false,
			}}
			scroll={{ x: 'max-content' }}
		/>
	)
}
export default Transactions

