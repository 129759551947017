import { Button, notification, Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { TenantApi } from '../../Api/TenantApi'
import { TenantUpdateConfigCommand } from '../../Command/TenantUpdateConfigCommand'
import DefaultLayout from '../../Component/Layout/Default'
import { trans } from '../../resources/localization/Localization'
import localStore from '../../Util/LocalStore'
import Item from './Item'
import NavItem from './NavItem'
const { TabPane } = Tabs

const Preferences: React.FC = () => {
	const [currentServiceConfig, setCurrentServiceConfig] = useState<TenantUpdateConfigCommand>()
	const [updateLoading, setUpdateLoading] = useState(false)
	const [currentNavItem, setCurrentNavItems] = useState('GENERAL');
	
	const handleChangeSwitch = (checked: boolean, key: string) => {
		console.log('run here ...')
		console.log(checked, key)
		setCurrentServiceConfig((prevState: any) => ({ ...prevState, [key]: checked }))
	}

	const updateConfig = async () => {
		setUpdateLoading(true)
		try {
			const response = await TenantApi.updateConfig(currentServiceConfig!)
			notification.success({ message: trans('preference.updateSuccess'), duration: 6 })
			localStore.removeItem('tenant')
			localStore.setJson('tenant', response.data)
			setUpdateLoading(false)
		} catch (err) {
			setUpdateLoading(false)
		}
	}

	useEffect(() => {
		const tenant = localStore.getItem('tenant')
		if (tenant) {
			const tenantObj = JSON.parse(tenant)
			setCurrentServiceConfig((prevState: any) => ({
				...prevState,
				enableShipment: tenantObj.config.shipmentConfig.enable,
				enableOrder: tenantObj.config.orderConfig.enable,
				enableGeneral: tenantObj.config.generalConfig.enable,
				enablePeerPayment: tenantObj.config.peerPaymentConfig.enable,
				enableGsa: tenantObj.config.gsaConfig.enable,
			}))
		}
	}, [])

	return (
		<DefaultLayout title={trans('sidebar.preference')}>
			<div className='preference'>
				<div className='preference-navigation'>
					<NavItem label={trans('preference.configGeneral')} icon={<i className='fa-solid fa-gear fa-1x'></i>} className={`${currentNavItem === 'GENERAL' ? 'active' : 'non-active'}`}/>
				</div>
				<div className='preference-content'>
					<div className='preference-content__heading'>
						<span className='label'>{trans('preference.configGeneral')}</span>
						<Tooltip title={trans('preference.configOnOff')} color='#cd5e77'>
							<i className='icon fa-regular fa-circle-info'></i>
						</Tooltip>
					</div>
					<div className='preference-content__switch'>
						<div className='preference-content__switch-group'>
							<Item
								checked={currentServiceConfig?.enableShipment!}
								label={trans('preference.shipment')}
								changeHandler={handleChangeSwitch}
								configKey='enableShipment'
							/>

							<Item
								checked={currentServiceConfig?.enableOrder!}
								label={trans('preference.buy')}
								changeHandler={handleChangeSwitch}
								configKey='enableOrder'
							/>

							<Item
								checked={currentServiceConfig?.enablePeerPayment!}
								label={trans('preference.payment')}
								changeHandler={handleChangeSwitch}
								configKey='enablePeerPayment'
							/>

							<Item
								checked={currentServiceConfig?.enableGsa!}
								label={trans('preference.gsa')}
								changeHandler={handleChangeSwitch}
								configKey='enableGsa'
							/>
						</div>
						<div className='flex justify-end'>
							<Button onClick={updateConfig} type='primary' className='text-500 fsz-14 text-base txt-capitalize' loading={updateLoading}>
								{trans('preference.saveConfig')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	)
}

export default Preferences