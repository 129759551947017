import  querystring  from 'query-string';
import apiClient from "../Util/ApiClient";
import {PackageOrdersCriteria, PackageOrderUpdateStatusCommand} from "../Criteria/PackageOrdersCriteria";
import { AxiosResponse } from "axios";
import { CreatePaymentInvoiceBody, InvoiceData, PaymentInvoiceInterface, PaymentLogInterface } from "../Interface/PackageOrderInterface";
import ExportExcelPackageOrder from "../Interface/ExportExcelPackageOrder";

export class PackageOrderApi {

    static getVendors() {
        return apiClient.get("/vendors", {
            params:{},
        })
    }

    static getPackageOrders(filter:PackageOrdersCriteria) {
        let limit = filter && filter.size ? filter.size : 25;
        let offset = filter && filter.page ? filter.page * limit : 0;
        return apiClient.get(`/admin/package-orders`,{
            params:{...filter,offset,limit,sort: 'createdAt:desc'}
        })
    }

    static updatePackageOrderStatus (packageCode:string,body:PackageOrderUpdateStatusCommand) {
        return apiClient.patch(`/admin/package-orders/${packageCode}/status`,body)
    }

    static createPaymentInvoice = (body: CreatePaymentInvoiceBody): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return apiClient.post (`admin/payment-invoices`, body)
    }

    static getPaymentInvoice = (id: string, owner: string): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return apiClient.get(`admin/payment-invoices/${id}?owner=${owner}`)
    }
    
    static syncPaymentInvoice = (): Promise<AxiosResponse<null>> => {
        return apiClient.post(`admin/payment-invoices/sync`)
    }

    static exportPaymentInvoice = ({invoiceId, owner}: {invoiceId: string, owner: string}): Promise<AxiosResponse<any>> => {
        return apiClient.post(`admin/payment-invoices/${invoiceId}/export?owner=${owner}`)
    }
    
    static updatePaymentInvoice = ({invoiceId, owner}: InvoiceData, body: CreatePaymentInvoiceBody): Promise<AxiosResponse<PaymentInvoiceInterface>> => {
        return apiClient.patch(`admin/payment-invoices/${invoiceId}?owner=${owner}`, body)
    }

    static getLogs = (id: string): Promise<AxiosResponse<PaymentLogInterface[]>> => {
        return apiClient.get(`admin/payment-invoices/${id}/logs`)
    }

    static updatePromoteStatus(packageOrderCode:string,body:any) {
        return apiClient.patch(`/admin/package-orders/${packageOrderCode}/promote-status`,body)
    }
    
    static exportExcel(body: ExportExcelPackageOrder) {
        const {code, customer, status, vendor, sort} = body
        const queryExport=querystring.stringify({code, customer, status, vendor, sort});
        return apiClient.post(`admin/package-orders/export?${queryExport}`, {}, {responseType: 'blob'})
    }
}
