import React from 'react';
import DefaultLayout from "../../Component/Layout/Default";

export const Home = (props: any) => {

    return (
        <DefaultLayout
            {...props}
            loading={false}
            title={"Bảng điều khiển"}
        >
            <div className="white-box">
                <div>Bảng điều khiển</div>
            </div>
        </DefaultLayout>
    );
}
