import { Col, Row } from 'antd'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatMoneyByUnit } from '../../../Util/string'

interface ItemProps {
	label: string
	labelClass?: string
	valueClass?: string
	number?: number | string
	value: any
	subValue?: any
	currencyValue: string
	currencySubValue?: string
    loading: boolean
}
function Item(props: ItemProps) {
	const { label, labelClass, number, value, subValue, currencyValue, currencySubValue, valueClass, loading } = props
	return (
		<Row className={'flex justify-between items-center mg-bt-4'}>
			{!loading ? <Col className={'items-center dpl-flex'}>
				<span className={`txt-size-h7 txt-color-black ${labelClass}`}>{label}</span>
				<span className={'txt-size-h8 txt-color-secondary mgl5'}>{number ? number : ''}</span>
			</Col> : <Skeleton width={80} height={20} />}
			{!loading ? <Col>
				{value || value === 0 ? (
					<Row className={'justify-end'}>
						<span className={`txt-size-h7 txt-color-black ${valueClass}`}>
							{formatMoneyByUnit(value, currencyValue)}
						</span>
						<span className={'txt-size-h7 txt-color-secondary mgl3'}>
							{subValue ? `(${formatMoneyByUnit(subValue, currencySubValue)})` : ''}
						</span>
					</Row>
				) : (
					<span className={'txt-size-h8'}>---</span>
				)}
			</Col> : <Skeleton width={120} height={20} />}
		</Row>
	)
}
export default Item