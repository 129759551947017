export const PERMISSION_ORDERS_VIEW = 'order:view:list'
export const PERMISSION_ORDER_DETAIL_VIEW = 'order:view:detail'
export const PERMISSION_ORDERS_ITEMS_VIEW = 'order:item:view'
export const PERMISSION_ORDER_UPDATE_STATUS = 'order:update:status'
export const PERMISSION_PACKAGE_ORDERS_VIEW = 'package-order:view:list'
export const PERMISSION_PACKAGE_ORDERS_UPDATE_STATUS = 'package-order:update:status'
export const PERMISSION_PACKAGE_ORDERS_VIEW_LOG = 'package-order:view:log'
export const PERMISSION_ORDER_PAYMENTREQUEST_VIEW = 'order:payment_request:view'
export const PERMISSION_ORDER_TRANSACTION_VIEW = 'order:transaction:view'
export const PERMISSION_CUSTOMER_LIST_VIEW = 'customer:view:list'
export const PERMISSION_ORDER_LIST_VIEW = 'order:package:view'
export const PERMISSION_CUSTOMER_DETAIL_VIEW = 'customer:view:detail'
export const PERMISSION_ORDER_HISTORY_VIEW = 'order:history:view'
export const PERMISSION_PACKAGE_ORDER_UPDATE_PROMOTE = 'package-order:update:promotion-status'
export const PERMISSION_PACKAGE_PREFERENCE = 'preference:general'
export const PERMISSION_PACKAGE_ORDER_VIEW_INVOICE = 'package-order:view:invoice'
export const PERMISSION_PACKAGE_ORDER_SYNC_INVOICE = 'package-order:update:status'
