import { Image, Steps } from 'antd'
import lodash, { reverse } from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import OrderDetailInterface from '../../Interface/OrderDetailInterface'
import StatusInterface from '../../Interface/StatusInterface'
import StepIconFinishedImg from '../../resources/images/icons/step-finish.png'
import StepIconImg from '../../resources/images/icons/step.png'
import { formatDateTime } from '../../Util/string'
import { trans } from '../../resources/localization/Localization'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Skeleton from 'react-loading-skeleton'

interface CustomDescriptionInterface {
	timestamp: string
	handlingTime?: string
}

type StepsOmitType = {
	status?: 'wait' | 'process' | 'finish' | 'error'
}
const { Step } = Steps
interface TrackOrderProps {
	statuses: StatusInterface[]
	details?: OrderDetailInterface
	milestones: MilestoneInterface[]
	loading: boolean
	getMilestones: () => void
}

const TrackOrder: React.FC<TrackOrderProps> = (props) => {
	const {xl, xs} = useBreakpoint()
	const { milestones, loading , statuses} = props

	const [milestonesRendered, setMileStoneRendered ] = useState<MilestoneInterface[]>([]);


	useEffect(() => {
	  if(xl) {
		setMileStoneRendered([...milestones])
	  }else{
		setMileStoneRendered([...reverse(milestones)])
	  }
	  console.log('milestone reverser', reverse(milestones))
	}, [milestones, xl])

	const customDot = (iconDot: ReactNode, { status }: StepsOmitType) => {
		return !loading ? <Image preview={false} src={status !== 'process' ? StepIconFinishedImg : StepIconImg} /> : <Skeleton width={10} height={10} circle/>
	}

	const CustomDescription = ({ timestamp, handlingTime }: CustomDescriptionInterface) => (
		<span className='flex flex-col items-start justify-start line-h-20'>
			<span className='text-gray'>{!loading ? timestamp : <Skeleton width={120} height={20}/>}</span>
			{handlingTime && (!loading ? <span className='text-gray'>{`(${handlingTime})`}</span> : <Skeleton width={80} height={20}/>)}
		</span>
	)
	return (
		<Steps className={`${!xl ? 'package-timeline' : ''}`} current={xl ? 0 : milestonesRendered.length - 1} progressDot={customDot} direction={`${!xl || xs ? 'horizontal' : 'vertical'}`}>
			{milestonesRendered &&
				milestonesRendered.length > 0 &&
				milestonesRendered.map(({ status, timestamp, handlingTime, id }: MilestoneInterface) => {
					const nameOfStatus = lodash.get(
						statuses?.find(({ code }: StatusInterface) => code === status),
						'name',
						'---'
					)
					const timestampRendered = lodash.isNull(timestamp) ? trans('order.undefined') : formatDateTime(timestamp)
					const handlingTimeRendered = lodash.isNull(handlingTime) ? trans('order.undefined') : `${handlingTime} ngày`
					return (
						<Step
							key={id}
							title={<span className='text-500'>{!loading ? nameOfStatus : <Skeleton width={80} height={22}/>}</span>}
							description={<CustomDescription timestamp={timestampRendered} handlingTime={handlingTimeRendered} />}
						/>
					)
				})}
		</Steps>
	)
}

export default TrackOrder

