export interface OrdersCriteria {
	page?: number | null;
	size?: number | null;
	sort?: string;
	providerOrderCode?: string | null;
	provider?: string;
	providerUsername?: string | null;
	username?: string | null;
	statuses?: string[] | null;
	marketplaces?: string[] | null;
	timestampTo?: string | null;
	timestampFrom?: string | null;
}

export const OrdersCriteriaDefault: OrdersCriteria = {
	page: 0,
	size: 1,
	sort: '',
	providerOrderCode: '',
	provider: '',
	providerUsername: '',
	username: '',
	statuses: [],
	marketplaces: [],
	timestampTo: '',
	timestampFrom: '',
};
