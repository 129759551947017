import {Button, List, Modal} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import lodash from 'lodash';
import {PackageOrderApi} from '../../Api/PackageOrderApi';
import {AxiosResponse} from 'axios';
import {PaymentLogInterface,} from '../../Interface/PackageOrderInterface';
import {trans} from '../../resources/localization/Localization';
import {ListItem} from './components/ListItem';

export interface ItemSelectToShowLogInterface {
    id: string;
    code: string;
    packageName: string;
}

interface LogModalProps {
    onCancel: () => void;
    itemSelectToShowLog?: ItemSelectToShowLogInterface;
}

const LogModal: FC<LogModalProps> = (props: LogModalProps) => {
    const { onCancel, itemSelectToShowLog } = props;
    const [dataSource, setDataSource] = useState<PaymentLogInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [initLoading, setInitLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [metadata, setMetadata] = useState<any>();

    const visible = useMemo(() => {
        return !!itemSelectToShowLog?.id;
    }, [itemSelectToShowLog]);

    useEffect(() => {
        if (!visible) {
            setDataSource([]);
            setMetadata({});
            return;
        }

        getData(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (metadata && metadata.page + 1 >= metadata.pageCount) setHasMore(false);
    }, [metadata]);

    const getData = (page: number) => {
        if (!itemSelectToShowLog?.id) {
            return;
        }

        if (page > 0) setLoading(true);
        PackageOrderApi.getLogs(itemSelectToShowLog.code)
            .then((res: AxiosResponse<PaymentLogInterface[]>) => {
                setMetadata({
                    pageCount: parseInt(lodash.get(res, 'headers.x-page-count')),
                    page: parseInt(lodash.get(res, 'headers.x-page-number')),
                    size: parseInt(lodash.get(res, 'headers.x-page-size')),
                    total: parseInt(lodash.get(res, 'headers.x-total-count')),
                });
                setDataSource([...dataSource, ...res.data]);
            })
            .catch((err: any) => {})
            .finally(() => {
                if (page === 0) setInitLoading(false);
                else setLoading(false);
            });
    };

    const loadMoreData = () => {
        if (loading) return;
        getData(metadata.page + 1);
    };

    const loadMore =
        !initLoading && hasMore ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type="primary" loading={loading} onClick={loadMoreData}>
                    {trans('button.loadmore')}
                </Button>
            </div>
        ) : null;

    return (
        <Modal
            visible={visible}
            title={
                <span className="line-h-22 txt-color-primary pd-0">
          {trans('packageOrders.log-title')}
                    <span className="mg-l-4 txt-title capitalize">
            {itemSelectToShowLog?.packageName}
          </span>
        </span>
            }
            onCancel={onCancel}
            closeIcon={
                <i className="far fa-times w-9 h-16 line-h-16 txt-color-black"></i>
            }
            centered
            footer={false}
            width={864}
            className="payment-log-modal"
        >
            <div
                style={{
                    maxHeight: 'calc(100vh - 300px)',
                    overflow: 'auto',
                    padding: 12,
                    paddingBottom: 20,
                }}
            >
                <List
                    loadMore={loadMore}
                    dataSource={dataSource}
                    loading={initLoading}
                    renderItem={(item: PaymentLogInterface, index: number) => {
                        return (
                            <ListItem
                                item={item}
                                index={index}
                                itemSelectToShowLog={itemSelectToShowLog}
                            />
                        );
                    }}
                />
            </div>
        </Modal>
    );
};
export { LogModal };
