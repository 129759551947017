import { Layout, Menu, Tooltip } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { useState } from "react"
import { Link } from "react-router-dom"
import { IMenuItem, menuItems } from "../../Config/Sidebar"
import uboxLogo from '../../resources/images/ubox-logo.png'

const { Sider } = Layout

interface Props {
	collapsed: boolean
	onCollapsed: (val: boolean) => void
}

export const SideBar = (props: Props) => {
	const [items] = useState<IMenuItem[]>(menuItems)

	const [selectedKeys, setSelectedKeys] = useState<Array<string>>(["DASHBOARD"])
	const { xs, md } = useBreakpoint()
	const { collapsed } = props

	const renderMenuItem = (item: IMenuItem) => {
		if (item.show) {
			if (item.children) {
				return (
					<Menu.ItemGroup
						key={item.key}
						className={"sidebar-item-has-children sidebar-menu-item"}
						title={<span className={'sidebar-menu-item-name sidebar-menu-item-name--parent'}>{collapsed ? <i className="fa-regular fa-ellipsis" /> : item.title}</span>}
					>
						{item.children.map((childItem) => (
							<Menu.Item
								title={null}
								key={childItem.key}
								className={`sidebar-menu-item menu-item-children`}
							>
								{collapsed && (
									<Tooltip placement={'right'} title={childItem.title} overlayClassName={'menu-item-tooltip'}>
										<Link className={"sidebar-menu-item-link"} to={childItem.url}>
											<span className={'sidebar-menu-item-icon'}>{childItem.icon}</span>
										</Link>
									</Tooltip>
								)}

								{!collapsed && (
									<Link className={"sidebar-menu-item-link"} to={childItem.url}>
										<span className={'sidebar-menu-item-icon'}>{childItem.icon}</span>
										<span className={'sidebar-menu-item-name'}>{childItem.title}</span>
									</Link>
								)}

							</Menu.Item>
						))}
					</Menu.ItemGroup>
				)
			} else {
				return (
					<Menu.Item
						key={item.key}
						className={`_sidebar_menu_${item.key.toLowerCase()} sidebar-menu-item`}
						title={null}
					>
						{collapsed && (
							<Tooltip placement={'right'} title={item.title} overlayClassName={'menu-item-tooltip'}>
								<Link className={"sidebar-menu-item-link"} to={item.url}>
									<span className={'sidebar-menu-item-icon'}>{item.icon}</span>
								</Link>
							</Tooltip>
						)}

						{!collapsed && (
							<Link className={"sidebar-menu-item-link"} to={item.url}>
								<span className={'sidebar-menu-item-icon'}>{item.icon}</span>
								<span className={'sidebar-menu-item-name'}>{item.title}</span>
							</Link>
						)}
					</Menu.Item>
				)
			}
		}
	}

	const handleSelectMenuItem = ({selectedKeys}: any) => {
		setSelectedKeys(selectedKeys)
	}

	return (
		<>
		{!md && collapsed ? null :
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={props.onCollapsed}
				collapsedWidth={56}
				className={`sidebar ${collapsed ? 'sidebar--collapse' : 'sidebar--expand'}`}
				trigger={
					!collapsed && (
						<i className="fa-regular fa-left-from-line trigger-icon" onClick={() => {
							props.onCollapsed(true)
						}} />
					)
				}
			>
				<div className={`sidebar__trigger-top ${xs ? "closable" : ""}`}>
					{collapsed ? (
						<i className={'fa-solid fa-angles-right trigger-icon'} onClick={() => props.onCollapsed(false)} />
					) : (
						<>
							<img src={uboxLogo} alt={'Ubox'} className={'trigger-logo'} />
							<i className={'pointer fa-solid fa-angles-left trigger-icon'} onClick={() => props.onCollapsed(true)} />
						</>
					)}
				</div>
				 <Menu
					className={'sidebar-menu-container'}
					mode="inline"
					onSelect={handleSelectMenuItem}
					selectedKeys={selectedKeys}
				>
					{items.map((item) => renderMenuItem(item))}
				</Menu>
			</Sider>}
		</>
	)
}

export default SideBar
