import { OrdersCriteria } from '../Criteria/OrdersCriteria';
import apiClient from '../Util/ApiClient';
const BASE_URL = '/admin/orders'
export class OrdersApi {
	static filter(filter: OrdersCriteria) {
		let limit = filter && filter.size ? filter.size : 25;
		let offset = filter && filter.page ? filter.page * limit : 0;
		return apiClient.get(BASE_URL, {
			params: {
				...filter,
				limit,
				offset,
				sort: 'createdAt:desc',
			},
		});
	}

	static getDetails(code: string) {
		return apiClient.get(`${BASE_URL}/${code}`)
	}

	static getMilestone(code: string) {
		return apiClient.get(`${BASE_URL}/${code}/milestones`, {params: {limit: 1000, sort: 'timestamp:desc'}})
	}

	static getItems(code: string) {
		return apiClient.get(`${BASE_URL}/${code}/items`)
	}
	static updateStatus(code: string, status: string) {
		return apiClient.patch(`${BASE_URL}/${code}/update_statuses`, {status})
	}
	static getTransactions(code: string) {
		return apiClient.get(`admin/orders/${code}/financials`, {
			params: { orderCode: code},
		});
	}
	static getPaymentRequests(code: string) {
		return apiClient.get(`admin/payment-requests`, {
			params: { size: 1000, orderCode: code },
		});
	}
	static updateStatusPaymentRequest(id: string, paymentStatus: string) {
		return apiClient.post(`admin/payment-requests/${id}/change-status`, {paymentStatus});
	}
	static updateMethodPaymentRequest(id: string, paymentMethod: string) {
		return apiClient.post(`admin/payment-requests/${id}/change-method`, {paymentMethod});
	}
	static getPackagesOfOrder(code: string) {
		return apiClient.get(`admin/orders/${code}/packages`, {params: {sort: 'createdAt:desc'}});
	}
}