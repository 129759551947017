import { Row } from 'antd'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import { OrdersApi } from '../../Api/OrdersApi'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import OrderItemInterface from '../../Interface/OrderItemInterface'
import PaymentMethodInterface from '../../Interface/PaymentMethodInterface'
import PaymentStatusInterface from '../../Interface/PaymentStatusInterface'
import StatusInterface from '../../Interface/StatusInterface'
import DefaultLayout from '../../Component/Layout/Default'
import Finance from './Finance'
import Heading from './Heading'
import Information from './Information'
import TabLayout from './TabLayout'
import TrackOrder from './TrackOrder'
import ProviderInterface from '../../Interface/ProviderInterface'
import OrderDetailInterface from '../../Interface/OrderDetailInterface'
import { trans } from '../../resources/localization/Localization'
import SecurityService from '../../Util/SecurityService'
import { PERMISSION_ORDER_HISTORY_VIEW } from '../../Config/permission'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const OrderDetail = () => {
	const { code } = useParams()
	const { lg } = useBreakpoint()

	const [details, setDetails] = useState<OrderDetailInterface>()
	const [statuses, setStatuses] = useState<StatusInterface[]>([])
	const [providers, setProviders] = useState([])
	const [milestones, setMilestones] = useState<Array<MilestoneInterface>>([])
	const [items, setItems] = useState<Array<OrderItemInterface>>([])
	const [currencyOfProvider, setCurrencyProvider] = useState('')
	const [transactions, setTransactions] = useState([])
	const [loadingTransactions, setLoadingTransactions] = useState(false)
	const [packages, setPackages] = useState([])
	const [loadingPackages, setLoadingPackages] = useState(false)
	const [loading, setLoading] = useState(false)
	const [expand, setExpand] = useState(true);
	

	const getDetails = useCallback(async () => {
		setLoading(true)
		try {
			const response = await OrdersApi.getDetails(code!)
			setDetails(response.data)
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [code])

	const getStatuses = useCallback(async () => {
		try {
			const response = await CategoryApi.getStatuses()
			setStatuses(response.data)
		} catch (err) {}
	}, [])

	const getProviders = useCallback(async () => {
		try {
			const response = await CategoryApi.getProviders()
			setProviders(response.data)
		} catch (err) {}
	}, [])

	const getMilestones = useCallback(async () => {
		try {
			const response = await OrdersApi.getMilestone(code!)
			setMilestones(response.data)
		} catch (err) {}
	}, [code])

	const getItems = useCallback(async () => {
		try {
			const response = await OrdersApi.getItems(code!)
			setItems(response.data)
		} catch (err) {}
	}, [code])

	const getTransactions = useCallback(async () => {
		setLoadingTransactions(true)
		try {
			const response = await OrdersApi.getTransactions(code!)
			setTransactions(response.data)
			setLoadingTransactions(false)
		} catch (err) {
			setLoadingTransactions(false)
		}
	}, [code])

	const fetchPackages = useCallback(async () => {
		setLoadingPackages(true)
		try {
			const response = await OrdersApi.getPackagesOfOrder(code!)
			setPackages(response.data)
			setLoadingPackages(false)
		} catch (err) {
			setLoadingPackages(false)
		}
	}, [code])

	const confirmProductChange = (code: string, id: string, confirm: boolean, successInfo: string) => {}

	useEffect(() => {
		const provider = providers.find((x: ProviderInterface) => x.code === details?.provider)
		if (provider) {
			const { code } = JSON.parse(lodash.get(provider, 'currency'))
			setCurrencyProvider(code)
		}
	}, [details?.provider, providers])

	useEffect(() => {
		getDetails()
		getItems()
		getMilestones()
		getProviders()
		getStatuses()
		getTransactions()
		fetchPackages()
	}, [getDetails, getItems, getMilestones, getProviders, getStatuses, getTransactions, fetchPackages])

	return (
		<DefaultLayout title={`${trans('order.details')} #${code}`}>
			<div className='order'>
				<Heading
					statuses={statuses}
					details={details}
					providers={providers}
					getDetails={getDetails}
					currencyOfProvider={currencyOfProvider}
					loading={loading}
				/>
				<Row className='order-content mg-t-12 gap-12 flex-nowrap'>
					<div className='order-content__info flex flex-col flex-basis-80pc flex-wrap justify-start'>
						<div className={`order-content__info-finance flex justify-between box-order position-re ${!expand ? 'items-center' : ''}`}>
							<Information details={details} providers={providers} currencyOfProvider={currencyOfProvider} expand={expand} loading={loading}/>
							<Finance details={details} currencyOfProvider={currencyOfProvider} expand={expand} loading={loading}/>
							<div
								className={`${expand ? 'orderDetail-expand' : 'orderDetail-collapse'} ${
									loading ? 'flex align-items-center' : ''
								} fsz-12`}
								onClick={() => setExpand(!expand)}>
								{!loading ? (
									<i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
								) : (
									<Skeleton width={14} height={14} className='mg-r-6' />
								)}
								{!loading ? (
									trans(`orderDetail.${expand ? 'collapse' : 'noCollapse'}`)
								) : (
									<Skeleton width={50} height={20} />
								)}
							</div>
						</div>
						<div>
							<TabLayout
								confirmProductChange={confirmProductChange}
								items={items}
								transactions={transactions}
								loadingTransactions={loadingTransactions}
								providers={providers}
								currencyOfProvider={currencyOfProvider}
								updateDetail={getDetails}
								fetchMileStone={getMilestones}
								packages={packages}
								loadingPackages={loadingPackages}
								loading={loading}
							/>
						</div>
					</div>
					{console.log('lg', lg)}
					{SecurityService.can(PERMISSION_ORDER_HISTORY_VIEW) && (
						<div className='order-milestones h-fit-content flex-basis-20pc'>
							<h2 className='heading white-nowrap font-size-16 line-h-24'>{trans('order.milestones')}</h2>
							<TrackOrder
								milestones={milestones}
								statuses={statuses}
								details={details}
								getMilestones={getMilestones}
								loading={loading}
							/>
						</div>
					)}
				</Row>
			</div>
		</DefaultLayout>
	)
}

export default OrderDetail

