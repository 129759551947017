import { List } from "antd";
import { FC, memo } from "react";
import { PaymentLogInterface } from "../../../Interface/PackageOrderInterface";
import { trans } from "../../../resources/localization/Localization";
import { formatDateTime } from "../../../Util/string";
import { ItemSelectToShowLogInterface } from "../LogModal";
import { ContentInvoiceUpdated, ContentInvoiceCreated } from "./ContentInvoice";
import { ContentOrder } from "./ContentOrder";
import { ContentPromoteOrder } from "./ContentPromoteOrder";

interface ListItemProps {
  item: PaymentLogInterface;
  index: number;
  itemSelectToShowLog?: ItemSelectToShowLogInterface;
}

const ListItem: FC<ListItemProps> = memo(
    ({ item, index, itemSelectToShowLog }: ListItemProps) => (
        <List.Item key={index} style={{ paddingTop: 8, paddingBottom: 6 }}>
          <div className="width-100pc">
        <span>
          <span className={'text-gray'}>
            {trans('packageOrders.log-timestamp')}:{' '}
          </span>
          <span className={'txt-color-black'}>
            {formatDateTime(item.timestamp)}
          </span>
          <span>
            <span className={'pd-l-12 text-gray'}>
              {trans('packageOrders.log-user')}:{' '}
            </span>

            <span className={'txt-color-black text-medium'}>
              {item.actor?.username}
            </span>
          </span>{' '}
        </span>
            <br />

            <div className={'txt-size-14'}>
              {
                {
                  CREATE_ORDER: (
                      <ContentOrder
                          item={item}
                          itemSelectToShowLog={itemSelectToShowLog}
                      />
                  ),
                  UPDATE_ORDER: (
                      <ContentOrder
                          item={item}
                          itemSelectToShowLog={itemSelectToShowLog}
                      />
                  ),
                  UPDATE_PROMOTE_ORDER: (
                      <ContentPromoteOrder
                          item={item}
                          itemSelectToShowLog={itemSelectToShowLog}
                      />
                  ),
                  UPDATE_INVOICE: <ContentInvoiceUpdated item={item} />,
                  CREATE_INVOICE: <ContentInvoiceCreated item={item} />,
                }[item.activity]
              }
            </div>
          </div>
        </List.Item>
    )
);

export {ListItem}
