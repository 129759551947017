import { Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { SupplierApi } from '../../Api/SupplierApi';
import CustomerCriteria from '../../Criteria/CustomerCriteria';
import { getCleanFormFilter } from '../../Util/string';
import Filter from './Filter'
import lodash from 'lodash'
import DefaultLayout from '../../Component/Layout/Default';
import { trans } from '../../resources/localization/Localization';
import List from './List';

interface CustomerProps {}
const Customers: React.FC<CustomerProps> = (props) => {
  const [filter, setFilter] = useState<CustomerCriteria | {}>({});
  const [searchParams, setSearchParams] = useSearchParams()
  const [customers, setCustomers] = useState<CustomerCriteria[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
  const [total, setTotal] = useState(0);

  const fetchCustomer = useCallback(async () => {
		const query = searchParams.get('query')
		const code = searchParams.get('code')
		const mobile = searchParams.get('mobile')
		const email = searchParams.get('email')
		const createdAtFrom = searchParams.get('createdAtFrom')
		const createdAtTo = searchParams.get('createdAtTo')
		const page = searchParams.get('page')
		const size = searchParams.get('size')
		const temp = {
			page,
			size,
			query,
			code,
			mobile,
			email,
			createdAtFrom,
			createdAtTo,
		}
		setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
		setLoading(true)
		try {
			const response = await SupplierApi.customerFilter(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setCustomers(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [searchParams])




  useEffect(() => {
    fetchCustomer()
  },[fetchCustomer])

  const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams({ ...params })
	}


	return (
		<DefaultLayout title={trans('sidebar.customers')} {...props}>
			<Filter
				handleFilter={handleFilter}
				setFilter={(obj: CustomerCriteria) => setFilter(obj)}
				filter={filter}
				loading={loading}
			/>
			<List
				total={total}
				page={page}
				size={pageSize}
				items={customers}
				handleFilter={handleFilter}
				setPage={(val: number) => setPage(val)}
				setPageSize={(val: number) => setPagesize(val)}
				filter={filter}
				loading={loading}
			/>
		</DefaultLayout>
	)
}

export default Customers
