import { memo } from 'react';
import {
  PaymentInvoiceInterface,
  BillTypeEnum,
  PaymentLogInterface,
  RequestBillStatus,
} from '../../../Interface/PackageOrderInterface';
import { trans } from '../../../resources/localization/Localization';

const billTypeMapping: any = {
  [BillTypeEnum.COMPANY]: trans('packageOrders.company'),
  [BillTypeEnum.PERSONAL]: trans('packageOrders.personal'),
};

const InvoiceInfo: any = memo(
  ({ invoiceData }: { invoiceData: PaymentInvoiceInterface }) => {
    return (
      <span>
        <span className="text-medium">{billTypeMapping[invoiceData.type]}</span>{' '}
        {invoiceData.type === BillTypeEnum.COMPANY ? (
          <>
            <span className="text-medium">{invoiceData.tax}</span>{' '}
            <span className="text-medium">{invoiceData.name}</span>{' '}
            <span className="text-medium">{invoiceData.address}</span>
          </>
        ) : (
          <>
            <span className="text-medium">{invoiceData.name}</span>{' '}
            <span className="text-medium">{invoiceData.address}</span>
          </>
        )}
      </span>
    );
  }
);

const InvoiceStatusMapping: any = {
  [RequestBillStatus.WAITING]: trans('packageOrders.waiting-bill'),
  [RequestBillStatus.SUCCESS]: trans('packageOrders.download-bill'),
};

export const ContentInvoiceCreated: any = memo(
  ({ item }: { item: PaymentLogInterface }) => {
    const invoiceData: PaymentInvoiceInterface | undefined =
      item.change?.newObject && item.change?.newObject[0];

    if (!invoiceData) {
      return null;
    }

    return (
      <div>
        <span>{trans('packageOrders.log-created-invoice')}</span>{' '}
        <InvoiceInfo invoiceData={invoiceData} />
      </div>
    );
  }
);

export const ContentInvoiceUpdated: any = memo(
  ({ item }: { item: PaymentLogInterface }) => {
    const changedValues: any = item.change?.valueChange?.changedValues || [];
    const newObject: any | PaymentInvoiceInterface = item.target
      ?.metadata as PaymentInvoiceInterface;

    changedValues.forEach((changedValue: any) => {
      newObject[changedValue.key] = changedValue.right;
    });

    if (!newObject) {
      return null;
    }

    if (newObject.status === RequestBillStatus.SUCCESS) {
      return (
        <div className={'txt-color-black'}>
          <span>
            {trans('packageOrders.log-updated-invoice-status-1')}{' '}
            <span className="PENDING">
              {InvoiceStatusMapping[RequestBillStatus.WAITING]}{' '}
            </span>
            {trans('packageOrders.log-updated-invoice-status-2')}{' '}
            <span className="text-color-primary">
              {InvoiceStatusMapping[RequestBillStatus.SUCCESS]}
            </span>
          </span>
        </div>
      );
    }

    return (
      <div className={'txt-color-black'}>
        <span>{trans('packageOrders.log-updated-invoice')}</span>{' '}
        <InvoiceInfo invoiceData={newObject} />
      </div>
    );
  }
);
