import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Button, Divider, Input, Modal} from "antd";
import {PackageOrderUpdateStatusCommand} from "../../../Criteria/PackageOrdersCriteria";
import {PackageOrderInterface} from "../../../Interface/PackageOrderInterface";
import {trans} from "../../../resources/localization/Localization";

type EntryPopupProps = {
    handleSubmit: (packageOrderCode: string, status?: string, ref?: string) => void;
    handleCancel: () => void;
    setUpdateStatusData: Dispatch<SetStateAction<PackageOrderUpdateStatusCommand>>;
    updateStatusData: PackageOrderUpdateStatusCommand;
    showEntryPopup: boolean
    selectedItem: PackageOrderInterface | undefined
}

function EntryPopup(props: EntryPopupProps) {
    const [entry, setEntry] = useState<string>('')

    useEffect(() => {
        setEntry('')
    }, [props.showEntryPopup])

    const validateEntryInput = () => {
        if(!entry || entry.length <1) return true;
        return false;
    }

    if(!props.showEntryPopup) return null;
    return (
        <Modal
            visible={props.showEntryPopup}
            centered
            className={'entry-popup-container'}
            title={<div className={'flex'}>
                <div className={''}>{trans('packageOrder.entry-input-popup-title')}</div>
                <div style={{flex:1}}><Divider/></div>
            </div>}
            onCancel={props.handleCancel}
            closeIcon={<i className="fa-solid fa-xmark"/>}
            // closable={false}
            footer={<>
                <Button
                    onClick={props.handleCancel}
                    type={'ghost'}>
                    <i className="fa-solid fa-xmark mg-r-5"/>
                    <span style={{lineHeight:1.675,textTransform:'capitalize'}}>{trans('packageOrder.entry-input-popup-cancel-btn')}</span>
                </Button>
                <Button
                    disabled={validateEntryInput()}
                    type={'primary'}
                    onClick={() => {
                        props.handleSubmit(props.selectedItem!.code, 'PAID', entry)
                    }}
                >
                    <i className="fa-solid fa-check mg-r-5"/>
                    <span style={{lineHeight:1.675,textTransform:'capitalize'}}>{trans('packageOrder.entry-input-popup-submit-btn')}</span>
                </Button>
            </>}
        >
            <div className={'flex'}>
                <div className={'mg-r-10'}>{trans('packageOrder.entry-input-popup-input-title')}
                    <span className={'mg-l-5 text-danger'}>*</span>
                </div>
                <Input
                    autoFocus
                    placeholder={trans('packageOrder.entry-input-popup-input-placeholder')}
                    value={entry}
                    onChange={(e) => setEntry(e.target.value.trim())}
                />
            </div>
        </Modal>
    )
}

export default EntryPopup;
