import React, { ReactNode } from 'react'
interface NavItemProps {
    label: string
    icon?: ReactNode
    className?: string
}
const NavItem:React.FC<NavItemProps> = ({label, icon, className}) => {
  return (
    <div className={`preference-navigation__item ${className ? className : ''} flex-nowrap`}>
        <span className='icon'>{icon}</span>
        <span className='label'>{label}</span>
    </div>
  )
}

export default NavItem