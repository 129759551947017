import { TenantUpdateConfigCommand } from '../Command/TenantUpdateConfigCommand'
import apiClient from '../Util/ApiClient'

export class TenantApi {
	
	static updateConfig = (command: TenantUpdateConfigCommand): Promise<any> => {
		return apiClient
			.patch(`/admin/tenants/update-config`, command)
	};
	static getCurrent = (): Promise<any> => {
		return apiClient.get(`/tenants/current`)
	};
	
}
