import apiClient from '../Util/ApiClient'
const BASE_URL = '/categories'
export class CategoryApi {
	static getProviders() {
		return apiClient.get(`${BASE_URL}/providers`, {
			params: { size: 1000 },
		})
	}

	static getStatuses() {
		return apiClient.get(`${BASE_URL}/order-statuses`, {
			params: { size: 1000 },
		})
	}

	static getMarketPalaces() {
		return apiClient.get(`${BASE_URL}/marketplaces`, {
			params: { size: 1000 },
		})
	}

	static getPaymentMethods() {
		return apiClient.get(`${BASE_URL}/payment-methods`)
	}

	static getPaymentStatuses() {
		return apiClient.get(`${BASE_URL}/payment-statuses`)
	}

	static getPaymentRequestTypes() {
		return apiClient.get(`${BASE_URL}/payment-request-types`)
	}

	static getTransactionStatuses() {
		return apiClient.get(`${BASE_URL}/transaction-statuses`)
	}

	static getTransactionTypes() {
		return apiClient.get(`${BASE_URL}/transaction-types`)
	}
	static getConnectionStatuses() {
		return apiClient.get(`${BASE_URL}/connection-statuses`)
	}
	static getPackageStatuses = () => {
		return apiClient.get(`${BASE_URL}/package-statuses`)
	}
}

