import { Table } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { CategoryApi } from '../../../../Api/CategoryApi'
import EmptyData from '../../../../Component/EmptyData'
import SkeletonTable, { SkeletonTableColumnsType } from '../../../../Component/SkeletonTable'
import PackageStatusInterface from '../../../../Interface/PackageStatusesInterface'
import { trans } from '../../../../resources/localization/Localization'
import { formatDateTime, formatNumber } from '../../../../Util/string'
import TracksPackage from './TrackPackages'
interface OrderItemsProps {
	packages: any
	loading: boolean
	orderDetail: any
}

const Packages: React.FC<OrderItemsProps> = (props) => {
	const [statuses, setStatuses] = useState<PackageStatusInterface[]>([])
	const { packages, loading } = props

	useEffect(() => {
		getStatuses()
	}, [])

	const getStatuses = () => {
		CategoryApi.getPackageStatuses()
			.then((res) => {
				setStatuses(lodash.sortBy(res.data, 'position'))
			})
			.catch((err) => {})
	}

	const packageInfoText = 'text-500 text-reg white-nowrap'
	const columns = [
		{
			title: '#',
			key: 'index',
			width: '1%',
			render: (text: string, record: any, index: number) => <span className={packageInfoText}>{++index}</span>,
		},
		{
			title: <span className='mg-l-4'>{trans('package.code')}</span>,
			key: 'code',
			dataIndex: 'code',
			width: '12%',
			render: (code: string) => <span className={packageInfoText}>{code}</span>,
		},
		{
			title: (
				<span className='flex flex-nowrap items-center mg-l-4'>
					<span className={`mg-r-4 white-nowrap ${packageInfoText}`}>{trans('package.weight')}</span>
					<span className={`text text-gray white-nowrap`}>{trans('package.noteForWeight')}</span>
				</span>
			),
			key: 'info',
			width: '28%',
			render: (record: any) => {
				const emptyTxt = '---'
				const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
					? emptyTxt
					: lodash.get(record, 'actualWeight', emptyTxt)
				const netWeight = lodash.isNull(lodash.get(record, 'netWeight'))
					? emptyTxt
					: lodash.get(record, 'netWeight', emptyTxt)
				const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
					? emptyTxt
					: lodash.get(record, 'packagingWeight', emptyTxt)
				const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
					? emptyTxt
					: lodash.get(record, 'dimensionalWeight', emptyTxt)
				return (
					<span className={packageInfoText}>{`${formatNumber(actualWeight)}kg / ${formatNumber(
						netWeight
					)}kg / ${formatNumber(packagingWeight)} kg / ${formatNumber(dimensionalWeight)}kg`}</span>
				)
			},
		},
		{
			title: (
				<span className='flex flex-nowrap items-center mg-l-4'>
					<span className='mg-r-4 white-nowrap'>{trans('package.details')}</span>
					<span className='text text-gray'>{trans('package.noteForDetail')}</span>
				</span>
			),
			key: 'details',
			width: '14%',
			render: (record: any) => {
				const emptyTxt = '---'
				const length = lodash.isNull(lodash.get(record, 'length')) ? emptyTxt : lodash.get(record, 'length', emptyTxt)
				const width = lodash.isNull(lodash.get(record, 'width')) ? emptyTxt : lodash.get(record, 'width', emptyTxt)
				const height = lodash.isNull(lodash.get(record, 'height')) ? emptyTxt : lodash.get(record, 'height', emptyTxt)
				return (
					<span className={packageInfoText}>{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(
						height
					)} (cm)`}</span>
				)
			},
		},
		{
			title: <span className='mg-l-4'>{trans('package.currentStock')}</span>,
			key: 'stock',
			width: '15%',
			render: (record: any) => (
				<span className={packageInfoText}>
					{record.mData?.warehouseCurrent?.name ? record.mData.warehouseCurrent.name : '---'}
				</span>
			),
		},
		{
			title: <span className='mg-l-4'>{trans('package.status')}</span>,
			key: 'notice',
			dataIndex: 'status',
			width: '12%',
			render: (status: string) => {
				const statusObj = statuses.find(({ code }) => code === status)
				const statusName = lodash.get(statusObj, 'name', '---')
				return (
					<span
						className={`text white-nowrap text-white rad-25 py-3 px-12`}
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
		{
			title: <span className='mg-l-4'>{trans('package.update')}</span>,
			key: 'notice',
			dataIndex: 'lastStatusTime',
			render: (lastStatusTime: any) => (
				<span className={packageInfoText}>
					{lodash.isNull(lastStatusTime) ? trans('orderDetail.undefined') : formatDateTime(lastStatusTime)}
				</span>
			),
		},
	]

	const components = { body: { row: TracksPackage } }
	const total = packages.length
	return (
		<SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]} className={`main-table`}>
			<Table
				components={components}
				rowKey={(record: any) => record.id}
				columns={columns}
				dataSource={packages}
				locale={{ emptyText: <EmptyData text={trans('package.noData')} /> }}
				loading={loading}
				className={`main-table`}
				pagination={false}
				size='small'
				scroll={{ x: 'max-content' }}
				onRow={(record, index) => {
					return { ...record, total, index, statuses }
				}}
			/>
		</SkeletonTable>
	)
}
export default Packages
