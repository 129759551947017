import { Button, Form, Input, Modal, notification, Radio, Spin } from 'antd';
import { FormInstance, useForm, useWatch } from 'antd/lib/form/Form';
import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { PackageOrderApi } from '../../Api/PackageOrderApi';
import {
  BillTypeEnum,
  CreatePaymentInvoiceBody,
  InvoiceData,
  PaymentInvoiceInterface,
  RequestBillFormNames,
} from '../../Interface/PackageOrderInterface';
import { trans } from '../../resources/localization/Localization';

interface IProps {
  isShowRequestBillModal: boolean;
  invoiceData?: InvoiceData;
  closeModal: () => void;
  finishedCallback: () => void;
}

export const ModalRequestBill: FC<IProps> = ({
  isShowRequestBillModal,
  invoiceData,
  finishedCallback,
  closeModal,
}) => {
  const [form]: [FormInstance<Record<RequestBillFormNames, any>>] = useForm();
  const billType: BillTypeEnum = useWatch(RequestBillFormNames.billType, form);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [curData, setCurData] = useState<any>();
  const [disabled, setDisabled] = useState(false);
  
  useEffect(() => {
    if (!isShowRequestBillModal) {
      return;
    }

    if (!invoiceData?.invoiceId || !invoiceData.owner) {
      form.setFieldsValue({
        [RequestBillFormNames.billType]: BillTypeEnum.COMPANY,
      });
      return;
    }

    setIsLoading(true);
    PackageOrderApi.getPaymentInvoice(invoiceData.invoiceId, invoiceData.owner)
      .then(({ data }: AxiosResponse<PaymentInvoiceInterface>): void => {
        setCurData(data)
        form.setFieldsValue({
          [RequestBillFormNames.billType]: data.type,
          [RequestBillFormNames.taxCode]:
            data.type === BillTypeEnum.COMPANY ? data.tax : '',
          [RequestBillFormNames.companyName]:
            data.type === BillTypeEnum.COMPANY ? data.name : '',
          [RequestBillFormNames.companyAddress]:
            data.type === BillTypeEnum.COMPANY ? data.address : '',
          [RequestBillFormNames.companyEmail]:
            data.type === BillTypeEnum.COMPANY ? data.email : '',
          [RequestBillFormNames.fullName]:
            data.type === BillTypeEnum.PERSONAL ? data.name : '',
          [RequestBillFormNames.address]:
            data.type === BillTypeEnum.PERSONAL ? data.address : '',
          [RequestBillFormNames.email]:
            data.type === BillTypeEnum.PERSONAL ? data.email : '',
        });
        setIsLoading(false);
      })
      .catch((error: any) => {
        handleError(error)
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowRequestBillModal, invoiceData]);

  const handleError: any = (error: any) => {
    if (error.response.status === 403) {
      notification.error({
        message: trans('message.not-have-permission'),
      });
      return;
    }
    notification.error({
      message: trans('message.unknown-error'),
    });
  };

  const handleSuccess: any = () => {
    notification.success({
      message: trans(
        invoiceData?.invoiceId
          ? 'packageOrders.update-bill-success'
          : 'packageOrders.request-bill-success'
      ),
    });
    onClose();
    finishedCallback();
  };

  const onFinish = (values: Record<RequestBillFormNames, any>) => {
    if (!invoiceData?.code || !invoiceData.owner) {
      onClose();
      return;
    }
    setIsLoading(true);

    const body: CreatePaymentInvoiceBody =
      billType === BillTypeEnum.COMPANY
        ? {
            type: billType,
            tax: values[RequestBillFormNames.taxCode],
            name: values[RequestBillFormNames.companyName],
            address: values[RequestBillFormNames.companyAddress],
            email: values[RequestBillFormNames.companyEmail],
            order: invoiceData.code,
            owner: invoiceData.owner,
          }
        : {
            type: billType,
            name: values[RequestBillFormNames.fullName],
            address: values[RequestBillFormNames.address],
            email: values[RequestBillFormNames.email],
            order: invoiceData.code,
            owner: invoiceData.owner,
            tax: '',
          };

    if (invoiceData.invoiceId) {
      delete body.order;
      delete body.owner;
      PackageOrderApi.updatePaymentInvoice(invoiceData, body)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    PackageOrderApi.createPaymentInvoice(body)
      .then(handleSuccess)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  const onChangeBillType = (e: any) => {
    form.setFieldsValue({
      [RequestBillFormNames.billType]: e.target.value,
    });

    if (e.target.value === BillTypeEnum.COMPANY) {
      form.validateFields([
        RequestBillFormNames.fullName,
        RequestBillFormNames.address,
        RequestBillFormNames.email,
      ]);
    } else {
      form.validateFields([
        RequestBillFormNames.taxCode,
        RequestBillFormNames.companyName,
        RequestBillFormNames.companyAddress,
        RequestBillFormNames.companyEmail,
      ]);
    }
  };

  return (
    <Modal
      visible={isShowRequestBillModal}
      onCancel={onClose}
      footer={null}
      centered
      className={'order-payment__request-bill-modal'}
      title={
        <h2 className="order-payment__request-bill-modal__header">
          <span className='capitalize'>
            {trans(
              invoiceData?.invoiceId
                ? 'packageOrders.waiting-bill'
                : 'packageOrders.request-bill'
            )}
          </span>
        </h2>
      }
      width={749}
      closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          autoComplete="off"
          labelAlign="left"
        >
          <div className="order-payment__request-bill-modal__form">
            <Form.Item
              name={RequestBillFormNames.billType}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
            >
              <Radio.Group onChange={onChangeBillType}>
                <Radio value={BillTypeEnum.COMPANY}>
                  {trans('packageOrders.company-bill')}
                </Radio>

                <Radio value={BillTypeEnum.PERSONAL}>
                  {trans('packageOrders.personal-bill')}
                </Radio>
              </Radio.Group>
            </Form.Item>

            {billType === BillTypeEnum.COMPANY && (
              <>
                <Form.Item
                  name={RequestBillFormNames.taxCode}
                  label={trans('packageOrders.tax-code')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.tax-code-validate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={trans('packageOrders.tax-code-placeholder')}
                    autoFocus
                    maxLength={20}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyName}
                  label={trans('packageOrders.company-name')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.company-name-validate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={trans(
                      'packageOrders.company-name-placeholder'
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyAddress}
                  label={trans('packageOrders.company-address')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.company-address-validate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={trans(
                      'packageOrders.company-address-placeholder'
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyEmail}
                  label={trans('packageOrders.email')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.email-validate'),
                    },
                    {
                      type: 'email',
                      message: 'Địng dạng email không hợp lệ',
                    },
                  ]}
                >
                  <Input
                    placeholder={trans('packageOrders.email-placeholder')}
                  />
                </Form.Item>
              </>
            )}

            {/* Personal */}
            {billType === BillTypeEnum.PERSONAL && (
              <>
                <Form.Item
                  name={RequestBillFormNames.fullName}
                  label={trans('packageOrders.full-name')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.full-name-validate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={trans('packageOrders.full-name-placeholder')}
                    autoFocus
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.address}
                  label={trans('packageOrders.address')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.address-validate'),
                    },
                  ]}
                >
                  <Input
                    placeholder={trans('packageOrders.address-placeholder')}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.email}
                  label={trans('packageOrders.email')}
                  rules={[
                    {
                      required: true,
                      message: trans('packageOrders.email-validate'),
                    },
                    {
                      type: 'email',
                      message: 'Địng dạng email không hợp lệ',
                    },
                  ]}
                >
                  <Input
                    placeholder={trans('packageOrders.email-placeholder')}
                  />
                </Form.Item>
              </>
            )}
          </div>

          <div className="order-payment__request-bill-modal__buttons">
            <Button type="ghost" className="mg-r-8" onClick={onClose}>
              <i className="fa-solid fa-xmark pd-r-8" />
              {trans('packageOrders.cancel')}
            </Button>

            <Form.Item shouldUpdate={(prevValues, curValues) => {
              let isDiff
              if(billType === 'COMPANY') {
                 isDiff = curData?.tax !== curValues.taxCode || curData?.name !== curValues.companyName || curData?.address !== curValues.companyAddress || curData?.email !== curValues.companyEmail
              }else {
                  isDiff = curData?.name !== curValues.fullName || curData?.address !== curValues.address || curData?.email !== curValues.email
              }
              setDisabled(!isDiff)
              return isDiff
            }} className="mg-bt-0">
              {() => {
                const values: Record<RequestBillFormNames, any> =
                  form.getFieldsValue();
                const isNotNull =
                  billType === 'COMPANY'
                    ? !!values[RequestBillFormNames.taxCode] &&
                      !!values[RequestBillFormNames.companyName] &&
                      !!values[RequestBillFormNames.companyAddress] &&
                      !!values[RequestBillFormNames.companyEmail]
                    : !!values[RequestBillFormNames.fullName] &&
                      !!values[RequestBillFormNames.address] &&
                      !!values[RequestBillFormNames.email];
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !isNotNull ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0 || disabled
                    }
                  >
                    <i className="fa-solid fa-check pd-r-8" />
                    {trans('packageOrders.confirm')}
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
