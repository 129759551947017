import { Col, Image, Row } from 'antd';
import React from 'react';
import EmptyImg from '../../resources/images/empty-3.png';

interface EmptyProps {
	text?: string;
	inRowExpanded?: boolean;
	canAddRecord?: boolean;
}

const EmptyData: React.FC<EmptyProps> = ({ text, canAddRecord, children }) => {
	return !canAddRecord ? (
			<Row justify='center' className='mg-bt-0' gutter={[0, 6]}>
				<Col lg={24} className='flex justify-center'>
					<Image preview={false} src={EmptyImg} />
				</Col>
				<Col lg={24} className='flex justify-center'>
					<span className={'text-reg text-gray'}>
						{text}
					</span>
				</Col>
			</Row>
	) : (
		<div>{children}</div>
	);
};

export default EmptyData;