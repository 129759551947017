import { Button, Col, List, Modal, notification, Pagination, Row } from "antd";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { CategoryApi } from "../../Api/CategoryApi";
import { PackageOrderApi } from "../../Api/PackageOrderApi";
import { PERMISSION_PACKAGE_ORDER_SYNC_INVOICE, PERMISSION_PACKAGE_ORDER_VIEW_INVOICE } from "../../Config/permission";
import {
    InvoiceData,
    PackageOrderInterface,
    PackageStatusEnum,
    RequestBillStatus
} from "../../Interface/PackageOrderInterface";
import { PackageVendorInterface } from "../../Interface/PackageVendorInterface";
import ProviderInterface from "../../Interface/ProviderInterface";
import { trans } from "../../resources/localization/Localization";
import SecurityService from "../../Util/SecurityService";
import { convertBlobDataToExcel } from "../../Util/string";
import ListItem from "./ListItem";
import { ItemSelectToShowLogInterface, LogModal } from './LogModal';
import { ModalRequestBill } from "./ModalRequestBill";

type PackageOrderListProps = {
    packageOrders: PackageOrderInterface[];
    isLoading: boolean,
    pagination: any,
    handleChangePage: (page: number, pageSize: number) => void;
    statuses: any[];
    vendors: PackageVendorInterface[]
    handleUpdatePackageOrderStatus: (packageOrderCode: string, s: string,selectedItem:PackageOrderInterface) => void;
    getPackageOrders: (filter?: any) => void;
    handleUpdateOrderPromoteStatus: (packageOrderCode: string, act: string) => void;
    filter: any
}

const PROMOTE_STATUS = [
    {code: 'PROMOTED', name: 'packageOrder.promoted-status', color: '#09B2AA'},
    {code: 'UNPROMOTED', name: 'packageOrder.unpromoted-status', color: '#7D7E7E'},
    {code: 'NOTIFIED',name: 'packageOrder.notified-status', color:'#008DF9'},
]

function PackageOrderList(props: PackageOrderListProps) {
    const {
        packageOrders, isLoading, pagination, statuses, vendors,
        handleChangePage, handleUpdatePackageOrderStatus, handleUpdateOrderPromoteStatus,
        getPackageOrders, filter
    } = props;

    const [visiblePreviewModal, setVisiblePreviewModal] = useState<boolean>(false);
    const [previewImgUrl, setPreviewImgUrl] = useState<string>();
    const [codeItemSelected, setCodeItemSelected] = useState<InvoiceData>()
    const [itemSelectToShowLog, setItemSelectToShowLog] = useState<ItemSelectToShowLogInterface>()
    const [providers,setProviders] = useState<ProviderInterface[]>([])
    const [isDisableSyncBtn, setIsDisableSyncBtn] = useState<boolean>(false)
    

    useEffect(()=> {
        CategoryApi.getProviders().then((response: AxiosResponse)=> {
            if(response.data) {
                setProviders(response.data)
            }
        }).catch((err)=> setProviders([]))
    },[])

    function handlePreviewImg(url: string) {
        setPreviewImgUrl(url);
        setVisiblePreviewModal(true);
    }

    function handleCancelPreviewImg() {
        setPreviewImgUrl('');
        setVisiblePreviewModal(false);
    }

    function onClickInvoice(record: PackageOrderInterface) {
        if (record.paymentInvoice?.status === RequestBillStatus.WAITING && !SecurityService.can(PERMISSION_PACKAGE_ORDER_VIEW_INVOICE)) {
            notification.error({
                message: trans('message.not-have-permission'),
              });
            return
        }

        if (record.paymentInvoice?.status === RequestBillStatus.SUCCESS) {
            PackageOrderApi.exportPaymentInvoice({invoiceId: record.paymentInvoice?.id, owner: record.customer}).then((res) => {
                var link: any = document.createElement("a");
                link.download = true;
                link.href = res.data.invoice;
                link.target = '_blank'
                link.click();
              }).catch((error: any) => {
                if (error.response.status === 403) {
                    notification.error({
                        message: trans('message.not-have-permission'),
                      });    
                      return
                }

                notification.error({
                    message: trans('message.unknown-error'),
                  });
              })
            return
        }

        setCodeItemSelected({
            code: record.code,
            invoiceId: record.paymentInvoice?.id,
            owner: record.customer
        })
    }

    function renderBill(status: PackageStatusEnum | string, record: PackageOrderInterface) {
        if (!SecurityService.can(PERMISSION_PACKAGE_ORDER_VIEW_INVOICE)) {
            return null
        }

        const buttonTextMapping: any = {
            [RequestBillStatus.SUCCESS]: 'packageOrders.download-bill',
            [RequestBillStatus.WAITING]: 'packageOrders.waiting-bill',
        }

        if (status === PackageStatusEnum.PAID) {
            return <Button
                className="orders-data__status-button capitalize"
                type="link"
                onClick={() => onClickInvoice(record)}>
                {trans(record.paymentInvoice?.status ? buttonTextMapping[record.paymentInvoice?.status] : 'packageOrders.request-bill')}
            </Button>
        }
    }

    function syncPaymentInvoice() {
        setIsDisableSyncBtn(true)
      PackageOrderApi.syncPaymentInvoice().then(() => {
        notification.success({
            message: trans('packageOrders.sync-invoice-success'),
          });
      }).catch((error: any) => {
        if (error.response.status === 403) {
            notification.error({
                message: trans('message.not-have-permission'),
              });    
              return
        }

        notification.error({
            message: trans('message.unknown-error'),
          });
      }).finally(() => {
        setIsDisableSyncBtn(false)
      })
    }



    const exportExcelHandler = useCallback(async () => {
        try {
            const res = await PackageOrderApi.exportExcel({...filter, sort: 'createdAt:desc'})
            convertBlobDataToExcel(res.data, 'DS_thanh_toan_goi_dich_vu')
        }catch(err: any) {notification.error({message: err.response})}
    }, [filter])

    

    const showTotal = (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total} ${trans('orders.record')}`
    return (
        <div className={'package-order-list'}>
            <div className={'package-order-header'}>
                <Row justify={'space-between'} className={'width-100pc'} align={'middle'}>
                    <Col xs={24} sm={12} md={17} lg={16} xl={16} xxl={16}>
                        <div className="flex align-center package-order-header-box">
                            <div className={'package-order-header__title'}>{trans('packageOrders.list')}</div>
                            <div className={'package-order-header__badge'}>{pagination.total}</div>
                        </div>
                    </Col>
                    <Col xs={14} sm={12} md={6} lg={8} xl={8} xxl={8} style={{flex:0}}>
                        {
                            SecurityService.can(PERMISSION_PACKAGE_ORDER_SYNC_INVOICE) && (
                                <div className={'flex align-items-center'}>
                                    <Button type="primary" onClick={syncPaymentInvoice} disabled={isDisableSyncBtn} className='mg-r-12'>
                                        <i className="fa-regular fa-arrows-repeat mg-r-8"/>{trans('packageOrders.sync_invoice')}
                                    </Button>
                                    <Button icon={<i className="far fa-file-export mg-r-7"></i>} type='primary'  onClick={exportExcelHandler}>{trans('packageOrders.export')}</Button>
                            </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
            <div style={{overflow:'auto'}}>
                <div className={'package-list-container'}>
                    <List
                        dataSource={packageOrders}
                        rowKey={(record: any) => record.code}
                        loading={isLoading}
                        pagination={{
                            ...pagination,
                            position: ['bottomRight'],
                            onChange: handleChangePage,
                            showTotal,
                            pageSizeOptions: ['10', '20', '25', '50', '100'],
                            locale: {items_per_page: `/ ${trans('orders.page')}`},
                            hideOnSinglePage: true,
                            showSizeChanger: true,
                        }}
                        renderItem={(item)=> {
                            return(
                                <ListItem
                                    packageItem={item}
                                    vendors={vendors}
                                    setItemSelectToShowLog={setItemSelectToShowLog}
                                    handlePreviewImg={handlePreviewImg}
                                    promoteStatuses={PROMOTE_STATUS}
                                    statuses={statuses}
                                    handleUpdateOrderPromoteStatus={handleUpdateOrderPromoteStatus}
                                    handleUpdatePackageOrderStatus={handleUpdatePackageOrderStatus}
                                    renderBill={renderBill}
                                    providers={providers}
                                />
                            )
                        }}
                    />
                </div>
            </div>
            <div className={'flex flr'}>
                {packageOrders &&  packageOrders.length >= 1 &&
                <Pagination
                    className={'ant-table ant-table-pagination'}
                    { ...pagination}
                    position={['bottomRight']}
                    onChange={handleChangePage}
                    // showTotal={pagination.total}
                    pageSizeOptions={['10', '20', '25', '50', '100']}
                    locale={{items_per_page: `/ ${trans('orders.page')}`}}
                    hideOnSinglePage={true}
                    showSizeChanger={true}
                />
                }
            </div>
            {visiblePreviewModal && <Modal
                visible={visiblePreviewModal}
                onCancel={handleCancelPreviewImg}
                footer={null}
                centered
                className={'order-payment__preview-modal'}
                title={<div className={'flex flex-col'}>
                    <span className={'order-payment__preview-modal_main'}>{trans('packageOrder.bill-image')}</span>
                    <span
                        className={'order-payment__preview-modal_sub'}>{trans('packageOrder.bill-image-preview-content')}</span>
                </div>}
            >
                <div>
                    <img src={previewImgUrl} alt={'preview-img'}/>
                </div>
            </Modal>}

            <ModalRequestBill
                isShowRequestBillModal={!!codeItemSelected}
                closeModal={() => setCodeItemSelected(undefined)}
                invoiceData={codeItemSelected}
                finishedCallback={getPackageOrders}
            />

            <LogModal
                onCancel={() => setItemSelectToShowLog(undefined)}
                itemSelectToShowLog={itemSelectToShowLog}
            />
        </div>

    )
}

export default PackageOrderList;