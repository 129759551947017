import React from "react";
import SecurityService from "../Util/SecurityService";
import {PERMISSION_CUSTOMER_LIST_VIEW, PERMISSION_ORDERS_VIEW, PERMISSION_PACKAGE_ORDERS_VIEW, PERMISSION_PACKAGE_PREFERENCE} from "./permission";
import {trans} from "../resources/localization/Localization";

export type showFunc = () => boolean

export interface IMenuItem {
    key: string
    title: string
    icon: any
    url: string
    show: boolean | showFunc
    children?: Array<IMenuItem>
}

export const menuItems: IMenuItem[] = [
    {
        key: "DASHBOARD2",
        title: trans("sidebar.dashboard"),
        show: true,
        icon:<i className="fas fa-home" />,
        url: "/",
    },
    {
        key: "ORDERS",
        title: trans('sidebar.order'),
        show: SecurityService.can(PERMISSION_ORDERS_VIEW),
        icon: <i className="fa-solid fa-paste" />,
        url: "/orders",
    },
    {
        key: "CUSTOMERS",
        title: trans('sidebar.customers'),
        show: SecurityService.can(PERMISSION_CUSTOMER_LIST_VIEW),
        icon: <i className="fa-solid fa-users" />,
        url: "/customers",
    },
    {
        key:'PACKAGE_ORDER',
        title: trans('sidebar.package-order'),
        show: SecurityService.can(PERMISSION_PACKAGE_ORDERS_VIEW),
        icon: <i className="fa-solid fa-credit-card-front"/>,
        url: '/package-orders'
    },
    {
        key:'PREFERENCES',
        title: trans('sidebar.preference'),
        show: SecurityService.can(PERMISSION_PACKAGE_PREFERENCE),
        icon: <i className="fa-solid fa-gear"></i>,
        url: '/preferences'
    }
]
