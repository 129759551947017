import { memo } from "react";
import { PackageStatusEnum, paymentStatusKeyMapping } from "../../../Interface/PackageOrderInterface";
import { trans } from "../../../resources/localization/Localization";

const ContentOrder: any = memo(({ item, itemSelectToShowLog }: any) => {
    const status: PackageStatusEnum =
        item.activity === 'CREATE_ORDER'
            ? PackageStatusEnum.PENDING
            : item.change?.valueChange?.changedValues[0]?.right;

    return (
        <>
      <span className={'txt-color-black'}>
        {trans(
            item.activity === 'CREATE_ORDER'
                ? 'packageOrders.log-created'
                : 'packageOrders.log-updated'
        )}
          :{' '}
      </span>
            <span className={'txt-color-black text-medium'}>
        {itemSelectToShowLog?.code}
      </span>{' '}
            <span>
        <span className={'txt-color-black'}>
          {trans('packageOrders.log-update-to')}:{' '}
        </span>

        <span className={'txt-color-black ' + status}>
          {trans(paymentStatusKeyMapping[status])}
        </span>
      </span>
        </>
    );
});

export{ContentOrder}
