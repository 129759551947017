import { Image, Tabs } from 'antd'
import { isNull } from 'lodash'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams, useSearchParams } from 'react-router-dom'
import EmptyData from '../../../Component/EmptyData'
import {
	PERMISSION_ORDERS_ITEMS_VIEW, PERMISSION_ORDER_TRANSACTION_VIEW, PERMISSION_PACKAGE_ORDERS_VIEW
} from '../../../Config/permission'
import OrderDetailInterface from '../../../Interface/OrderDetailInterface'
import OrderItemCommand from '../../../Interface/OrderItemInterface'
import PackageInterface from '../../../Interface/PackageInterface'
import ProviderInterface from '../../../Interface/ProviderInterface'
import TransactionInterface from '../../../Interface/TransactionInterface'
import imageError from '../../../resources/images/stop.png'
import { trans } from '../../../resources/localization/Localization'
import { TAB_PACKAGES, TAB_PRODUCT, TAB_TRANSACTION } from '../../../Util/Constants'
import SecurityService from '../../../Util/SecurityService'
import OrderItems from './OrderItems'
import Packages from './Packages'
import Transactions from './Transactions'

const { TabPane } = Tabs
interface TabProps {
	items: OrderItemCommand[]
	confirmProductChange: (code: string, id: string, confirm: boolean, successInfo: string) => void
	providers: ProviderInterface[]
	currencyOfProvider: string
	transactions: TransactionInterface[]
	packages: PackageInterface[]
	loadingTransactions: boolean
	loadingPackages: boolean
	details?: OrderDetailInterface,
	loading: boolean
	updateDetail: () => void
	fetchMileStone: () =>  void
}
interface TitleProps {
	label: string
	total?: number
	isActive: boolean
	loading: boolean
}
const CustomerTabTitle: React.FC<TitleProps> = ({ label, total, isActive, loading }) => {
	return (
		<span className='px-12 pd-bt-4 flex items-center line-h-20'>
			<span className={`mg-r-4 ${isActive ? 'text-md text-md-500' : 'text-md text-md-gray'}`}>{!loading ? label : <Skeleton width={80} height={22} />}</span>
			{!loading ? <span className={`${isActive ? 'badge badge-active' : 'badge'}`}>{total}</span>: <Skeleton width={30} height={24} borderRadius={20}/>}
		</span>
	)
}

const TabLayout: React.FC<TabProps> = (props) => {
	let { code } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const exchangeRate = 1
	const { items, currencyOfProvider, transactions, loadingTransactions, packages, loadingPackages, details, loading } = props
	const [currentTabKey, setCurrentTabKey] = useState('')
	
	const handleChangeTab = (activeKey: string) => {
		setCurrentTabKey(activeKey)
		setSearchParams({ tab: activeKey })
	}

	useEffect(() => {
		const keyFromParams = isNull(searchParams.get('tab')) ? TAB_PRODUCT : searchParams.get('tab')
		setCurrentTabKey(keyFromParams!)
	}, [code, searchParams])

	const permissionsInTab = () => {
		return <Image src={imageError} alt='image-403' preview={false} width={250} height={250} />
	}



	return (
		<div className='order-tabs'>
			<Tabs activeKey={currentTabKey} onChange={handleChangeTab}>
				{SecurityService.can(PERMISSION_ORDERS_ITEMS_VIEW) ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.productTab')}
								total={items.length}
								isActive={currentTabKey === TAB_PRODUCT}
								loading={loading}
							/>
						}
						key={TAB_PRODUCT}>
						<OrderItems items={items} exchangeRate={exchangeRate} currencyOfProvider={currencyOfProvider} loading={loading} />
					</TabPane>
				) : !SecurityService.can(PERMISSION_ORDERS_ITEMS_VIEW) && searchParams.get('tab') === TAB_PRODUCT ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.productTab')}
								total={items.length}
								isActive={currentTabKey === TAB_PRODUCT}
								loading={loading}
							/>
						}
						key={TAB_PRODUCT}>
						<div className='flex justify-center pd-12 width100pc'>{permissionsInTab()}</div>
					</TabPane>
				) : (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.productTab')}
								total={undefined}
								isActive={currentTabKey === TAB_PRODUCT}
								loading={loading}
							/>
						}
						key={TAB_PRODUCT}>
						<EmptyData />{' '}
					</TabPane>
				)}
				{SecurityService.can(PERMISSION_PACKAGE_ORDERS_VIEW) ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.packageTab')}
								total={packages.length}
								isActive={currentTabKey === TAB_PACKAGES}
								loading={loading}
							/>
						}
						key={TAB_PACKAGES}>
						<Packages packages={packages} loading={loadingPackages} orderDetail={details}/>
					</TabPane>
				) : !SecurityService.can(PERMISSION_PACKAGE_ORDERS_VIEW) && searchParams.get('tab') === TAB_PACKAGES ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.packageTab')}
								total={packages.length}
								isActive={currentTabKey === TAB_PACKAGES}
								loading={loading}
							/>
						}
						key={TAB_PACKAGES}>
						<div className='flex justify-center pd-12 width100pc'>{permissionsInTab()}</div>
					</TabPane>
				) : (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.packageTab')}
								total={undefined}
								isActive={currentTabKey === TAB_PACKAGES}
								loading={loading}
							/>
						}
						key={TAB_PACKAGES}>
						<EmptyData />
					</TabPane>
				)}

				{SecurityService.can(PERMISSION_ORDER_TRANSACTION_VIEW) ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.transactions')}
								total={transactions.length}
								isActive={currentTabKey === TAB_TRANSACTION}
								loading={loading}
							/>
						}
						key={TAB_TRANSACTION}>
						<Transactions
							items={transactions}
							loading={loadingTransactions}
						/>
					</TabPane>
				) : !SecurityService.can(PERMISSION_ORDER_TRANSACTION_VIEW) && searchParams.get('tab') === TAB_TRANSACTION ? (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.transactions')}
								total={transactions.length}
								isActive={currentTabKey === TAB_TRANSACTION}
								loading={loadingTransactions}
							/>
						}
						key={TAB_TRANSACTION}>
						<div className='flex justify-center pd-12 width100pc'>{permissionsInTab()}</div>
					</TabPane>
				) : (
					<TabPane
						tab={
							<CustomerTabTitle
								label={trans('order.transactions')}
								total={undefined}
								isActive={currentTabKey === TAB_TRANSACTION}
								loading={loadingTransactions}
							/>
						}
						key={TAB_TRANSACTION}>
						<EmptyData />
					</TabPane>
				)}
			</Tabs>
		</div>
	)
}

export default TabLayout

