import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, Row, Select} from "antd";
import {trans} from "../../resources/localization/Localization";
import lodash from "lodash";
import ConnectionBar from "../../Component/ConnectionBar";
import {RedoOutlined} from "@ant-design/icons";
import {PackageOrderCriteriaDefault, PackageOrdersCriteria} from "../../Criteria/PackageOrdersCriteria";
import {useSearchParams} from "react-router-dom";
import {PackageVendorInterface} from "../../Interface/PackageVendorInterface";


type PackageOrderFilterProps = {
    statuses:any[];
    vendors:PackageVendorInterface[];
}

function PackageOrderFilter(props:PackageOrderFilterProps) {
    const [showFilter, setShowFilter] = useState(true);
    const [filter, setFilter] = useState<PackageOrdersCriteria|any>(PackageOrderCriteriaDefault);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchExpand, setSearchExpand] = useState(false);
    const activeStatus = searchParams.get('status')
        ?.split(',') || []

    const {
        statuses,vendors
    } = props;
    useEffect(() => {
        setFilter({
            ...PackageOrderCriteriaDefault,
            vendor: searchParams.get('vendor') || "",
            code: searchParams.get('code') || "",
            customer: searchParams.get('customer') || "",
            status: searchParams.get('status') || "",
        })
    }, [searchParams])


    const handleChangeFilter = (param: string, value: any) => {
        const newFilter = {
            ...filter,
            [param]: value
        }
        setFilter(newFilter)
    }

    const handleSubmitFilter = () => {
        setSearchParams({
            ...lodash.omitBy(filter, value => !value)
        })
    }

    const handleResetFilter = () => {
        setFilter(PackageOrderCriteriaDefault)
        setSearchParams({})
    }

    const handleChangeFilterAndSubmit = (param: string, value: any) => {
        const newFilter = {
            ...filter,
            [param]: value
        }
        setFilter(newFilter)
        setSearchParams({
            ...lodash.omitBy(newFilter, value => !value)
        })
    }

    const handleSelectStatus = (status: string) => {
        switch(status !== '') {
            case true:
                let statuses = [...activeStatus]
                if(statuses.includes(status)) {
                    handleResetFilter()
                    return
                }
                statuses = [status]
                setSearchParams({
                    status: statuses.join(',')
                })
                break
            default:
                handleResetFilter()
                break
        }
    }

    return (
        <Row className='orders-filter mg-0 mg-bt-12'>
            <Row className={`cursor-pointer orders-filter__title ${showFilter ? 'mg-bt-20' : ''}`} align='middle'
                 onClick={() => setShowFilter(!showFilter)}
            >
                <Col className='title'>
                    <h3>{trans('orders.search')}</h3>
                </Col>
                <Col className={'divider'}>
                    <div className='cursor-pointer'>
                        <Divider className='mg-0'/>
                    </div>
                </Col>
                <Col>
                    <i className={`cursor-pointer fa-solid fa-angle-${showFilter ? 'up' : 'down'}`}
                       onClick={() => setShowFilter(!showFilter)}
                    />
                </Col>
            </Row>
            {showFilter && (
                <>
                    <Row className={`orders-filter__statuses mg-bt-10`} align='middle'>
                        <span className='orders-filter__statuses-label'>{`${trans('packageOrders.status')}:`}</span>
                        <span
                            className={activeStatus.length === 0 ? 'status active' : 'status'}
                            onClick={() => {
                                handleSelectStatus('');
                            }}>
									{trans('packageOrder.find-all')}
								</span>
                        {statuses?.map((status: any) => {
                            let isSelect = false;
                            if (filter) {
                                let temp = filter.status;
                                if (temp && temp.includes(status.code)) {
                                    isSelect = !isSelect;
                                }
                            }
                            return (
                                <span
                                    className={isSelect ? 'status active' : 'status'}
                                    key={status.code}
                                    onClick={() => {
                                        handleChangeFilterAndSubmit('status',status.code);
                                    }}>
									{trans(status.name)}
								</span>
                            )
                        })}
                    </Row>
                    {searchExpand && (
                        <div>
                            <Form name='order-filter'
                                  onFinish={handleSubmitFilter}
                                  className='orders-filter__form package-filter__form'
                                  // labelCol={{sm:6,md:6,lg:4,xl:5}}
                                  colon={false}
                                  labelAlign={'left'}
                            >
                                {console.log('filter', filter)}
                                <Row gutter={[32, 10]} align='middle' className='orders-filter__form-input'>
                                    <Col xl={8} md={12} sm={12} xs={24}>
                                        <Form.Item label={<span className={'form-label'}>{trans('packageOrders.code')}</span>}>
                                            <Input
                                                placeholder={trans('packageOrders.code')}
                                                onChange={(e) =>{
                                                    console.log('run here...')
                                                    setFilter({
                                                        ...filter,
                                                        code: e.target.value.trimStart().replace(/\s\s+/g, ""),
                                                    })
                                                }}
                                                value={filter.code ? filter.code : ''}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={8} md={12} sm={12} xs={24} className='package-order__filter'>
                                        <Form.Item
                                            label={<span className={'form-label'}>{trans('packageOrders.vendor')}</span>}
                                            className='selection'>
                                            <Select
                                                value={filter.vendor || undefined}
                                                placeholder={trans('packageOrders.vendorPlaceholder')}
                                                onChange={(value) => {
                                                    handleChangeFilter('vendor', value)
                                                }}
                                                dropdownStyle={{ minWidth: '250px' }}
                                                notFoundContent={null}>
                                                {vendors?.map((vendor: PackageVendorInterface) => (
                                                    <Select.Option key={vendor.id} value={vendor.code}>
                                                        <ConnectionBar
                                                            divider='|'
                                                            inFilter
                                                            sizeImg={15}
                                                            logo={vendor.avatar}
                                                            code={vendor.code}
                                                            username={vendor.name}
                                                            className='font-size-12 line-h-20'
                                                        />
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={8} md={12} sm={12} xs={24} className='package-order__filter'>
                                        <Form.Item
                                            label={<span className={'form-label'}>{trans('packageOrders.customer')}</span>}
                                            labelAlign='left'>
                                            <Input
                                                placeholder={trans('packageOrders.username-placeholder')}
                                                onChange={(e) =>
                                                    setFilter({
                                                        ...filter,
                                                        customer: e.target.value.trimStart().replace(/\s\s+/g, ""),
                                                    })
                                                }
                                                value={filter.customer ? filter.customer : ''}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    )}
                    <Row align='middle' justify='space-between' className='actions'>
                        <Col span={12}>
							<span className='collapse-expand text cursor-pointer mg-r-7' onClick={() => setSearchExpand(!searchExpand)}>{`${
                                searchExpand ? trans('orders.filterCollapse') : trans('orders.filterOpen')
                            }`}</span>
                            <i
                                className={`cursor-pointer fa-solid fa-angle-${searchExpand ? 'up' : 'down'}`}
                                onClick={() => setSearchExpand(!searchExpand)}
                            />
                        </Col>
                        {showFilter && (
                            <Col span={12} className={'actions-btn'}>
                                {
                                    !lodash.isEmpty(lodash.pickBy(filter, (val: any) => !lodash.isEmpty(val))) &&
                                    <Button className='clear' onClick={handleResetFilter} icon={<RedoOutlined />}>
                                        <span className='mg-0'>{trans('orders.cleanFilter')}</span>
                                    </Button>
                                }
                                <Button className='btn btn-primary' type='primary' htmlType='submit' form='order-filter'>
                                    {trans('orders.search')}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </>
            )}
        </Row>
    )
}

export default PackageOrderFilter;
