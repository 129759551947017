import { AxiosPromise } from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SupplierApi } from '../../../Api/SupplierApi'
import { formatMoneyByUnit } from '../../../Util/string'

interface BalanceRowProps {
	id: string
	currency: string
}
const BalanceRow: React.FC<BalanceRowProps> = (props) => {
	const {id, currency } = props
    const {username} = useParams()
    const [balance, setBalance] = useState('');
    
    useEffect(() => {
        const fetchBalanceOfConnection = async () => {
            try {
                const res = await SupplierApi.getBalanceOfConnection(username!, id)
                console.log('res', res)
                setBalance(res.data.balance)
            }catch(err) {}
        }
        fetchBalanceOfConnection()
    
    }, [id, username])
    
	return <span className='text-reg text-500 text-red rad-20 py-4'>{formatMoneyByUnit(balance, currency)}</span>
}

export default BalanceRow
