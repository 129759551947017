export interface PackageOrdersCriteria {
    page?: number,
    size?: number,
    sort?: string,
    code?: string,
    customer?: string,
    status?: string,
    vendor?: string,
}

export const PackageOrderCriteriaDefault:PackageOrdersCriteria = {
    code: "",
    customer: "",
    page: 0,
    size: 25,
    sort: "",
    status: "",
    vendor: ""

}


export interface PackageOrderUpdateStatusCommand {
    status:string,
    ref?:string,
}
