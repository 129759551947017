import { RedoOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Divider, Form, Input, Row } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import lodash, { identity, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CustomerCriteria, { CustomerCriteriaDefault } from '../../Criteria/CustomerCriteria'
import { trans } from '../../resources/localization/Localization'
import { CREATED_AT_FROM, CREATED_AT_TO } from '../../Util/Constants'

const { Item } = Form
const { RangePicker } = DatePicker
const beginDateFormat = 'YYYY-MM-DD HH:mm:00'
const endDateFormat = 'YYYY-MM-DD HH:mm:59'

interface FilterProps {
	filter: CustomerCriteria
	setFilter: (obj: CustomerCriteria | {}) => void
	handleFilter: (params: CustomerCriteria) => void
	loading: boolean
}
const Filter: React.FC<FilterProps> = (props) => {
	const { setFilter, filter, handleFilter } = props
	const [form] = Form.useForm()
	const {xs} = useBreakpoint()
	const [showFilter, setShowFilter] = useState(true)

	useEffect(() => {
		if(xs) {
			setShowFilter(false)
		}
		setShowFilter(true)
	},[xs])

	const onFinish = () => {
		handleFilter({ ...lodash.pickBy(filter, (val) => !isEmpty(val) && identity(val)), page: 0 })
	}

	const handleChangeDate = (dates: any, dateString: string[]) => {
		setFilter({
			...filter,
			[CREATED_AT_FROM]: dates ? moment(dates[0].format(beginDateFormat)).toISOString() : null,
			[CREATED_AT_TO]: dates ? moment(dates[1].format(endDateFormat)).toISOString() : null,
		})
	}

	const clearFilter = () => {
		handleFilter(lodash.pickBy(CustomerCriteriaDefault, (val) => !isEmpty(val) && identity(val)))
	}

	const phoneValidation = (str: string) => {
		// from Reg101
		const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
		return !(!str || regex.test(str) === false)
	}

	return (
		<Row className='orders-filter mg-0 mg-bt-12'>
			<Row className={`orders-filter__title ${showFilter ? 'mg-bt-20' : ''}`} align='middle'>
				<Col className='title'>
					<h3 className='text-md text-md'>{trans('customers.search')}</h3>
				</Col>
				<Col className='divider'>
					<Divider className='mg-0' />
				</Col>
				<Col>
					<i
						className={`cursor-pointer fa-solid fa-angle-${showFilter ? 'up' : 'down'}`}
						onClick={() => setShowFilter(!showFilter)}
					/>
				</Col>
			</Row>
			{showFilter && (
				<Col span={24}>
					<Form name='order-filter' onFinish={onFinish} className='orders-filter__form extends' form={form}>
						<Row gutter={[32, 12]}  className='orders-filter__form-input'>
							<Col xl={6} lg={8} md={12} className='code flex-auto'>
								<Item
									label={<span className={'form-label'}>{`${trans('customer.code')}:`}</span>}
									labelAlign='left'
									colon={false}>
									<Input
										placeholder={trans('customers.codePalace')}
										onChange={(e) =>
											setFilter({
												...filter,
												code: e.target.value,
											})
										}
										value={filter.code ? filter.code : ''}
										allowClear
									/>
								</Item>
							</Col>
							<Col xl={6} lg={8} md={12} className='username flex-auto'>
								<Item
									label={<span className={'form-label'}>{`${trans('customers.username')}:`}</span>}
									labelAlign='left'
									colon={false}>
									<Input
										placeholder={trans('customers.usernamePalace')}
										onChange={(e) => {
											setFilter({
												...filter,
												query: e.target.value,
											})
										}}
										value={filter.query ? filter.query : ''}
										allowClear
									/>
								</Item>
							</Col>
							<Col xl={6} lg={8} md={12} className='phone flex-auto'>
								<Item
									label={<span className={'form-label'}>{`${trans('customers.phone')}:`}</span>}
									labelAlign='left'
									colon={false}
									// name='phone'
									// rules={[{required: false},
									// 	({ getFieldValue }) => ({
									// 		validator(_, value) {
									// 				if (!value || phoneValidation(value)) {
									// 					return Promise.resolve()
									// 				}
									// 				return Promise.reject(new Error(trans('customer.requirePhoneMess')))
													
									// 		},
									// 	}),
									// ]}
									>
									<Input
										placeholder={trans('customers.phonePalaceHolder')}
										onChange={(e) => {
											setFilter({
												...filter,
												mobile: e.target.value,
											})
										}}
										value={filter.mobile ? filter.mobile : ''}
										allowClear
									/>
								</Item>
							</Col>
							<Col xl={6} lg={8} md={12} className='email flex-auto'>
								<Item
									label={<span className={'form-label'}>{`${trans('customers.email')}:`}</span>}
									labelAlign='left'
									colon={false}>
									<Input
										placeholder={trans('customers.emailPalaceHolder')}
										onChange={(e) =>
											setFilter({
												...filter,
												email: e.target.value,
											})
										}
										value={filter.email ? filter.email : ''}
										allowClear
									/>
								</Item>
							</Col>
							<Col
								lg={16}
								xl={12}
								md={12}
								className='orders-filter__form-input--date input--date timestamp flex-auto'>
								<Item
									label={
										<span className='form-label form-label__createdAt'>{`${trans(
											'customers.createdAt'
										)}:`}</span>
									}
									labelAlign='left'
									colon={false}>
									<RangePicker
										value={[
											filter.createdAtFrom ? moment(filter.createdAtFrom) : null,
											filter.createdAtTo ? moment(filter.createdAtTo) : null,
										]}
										placeholder={[trans('orders.createdAtPlaceholder'), trans('orders.createdAtPlaceholder')]}
										format={'DD-MM-YYYY HH:mm'}
										suffixIcon={<i className='far fa-calendar-alt' />}
										onChange={(values: any, dateString: string[]) => handleChangeDate(values, dateString)}
										showTime
										className='width100pc'
									/>
								</Item>
							</Col>
						</Row>
					</Form>
				</Col>
			)}
			{showFilter && (<Row align='middle' justify='end' className='actions'>
				
					<Col span={24} className={'actions-btn'}>
						{!lodash.isEmpty(lodash.pickBy(filter, (val: any) => !isEmpty(val) && identity(val))) && (
							<Button className='clear' onClick={clearFilter} icon={<RedoOutlined />}>
								<span className='mg-0'>{trans('orders.cleanFilter')}</span>
							</Button>
						)}
						<Button className='btn btn-primary' type='primary' htmlType='submit' form='order-filter'>
							{trans('orders.search')}
						</Button>
					</Col>
			</Row>
			)}
		</Row>
	)
}

export default Filter

