import { Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
interface TruncateProps {
	defaultValue?: string;
	className?: string;
	editable?: boolean;
	isOrder?: boolean;
	id?: string;
}

const TruncateText: React.FC<TruncateProps> = (props) => {	
	const { defaultValue, editable, className } = props;
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState(defaultValue);
	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleEditInline = (val: string) => {
		setEditing(false);
		setValue(val);
	};

	return (
		<>
			<Paragraph
				className={`${editing ? 'editing' : ''} flex items-center mg-bt-0 whitespace-nor-impt ${
					className ? className : ''
				}`}
				editable={
					editable
						? {
								icon: (
									<span className={'mg-0'}>
										<i className='fa-regular fa-pen-to-square' onClick={() => setEditing(true)}></i>
									</span>
								),
								tooltip: false,
								editing: editing,
								onChange: handleEditInline,
								onCancel: () => setEditing(false),
								autoSize: { minRows: 1, maxRows: 3 },
						  }
						: false
				}
				ellipsis={{
					rows: 1,
					expandable: true,
					symbol: (
						<Tooltip placement='topRight' title={<span className='text-reg'>{value}</span>} color='#CD5E77'>
							<i className='far fa-info-circle cursor-pointer' style={{ color: '#CD5E77' }}></i>
						</Tooltip>
					),
				}}>
				{!editing ?  value : value === '---' ? '' : value}
			</Paragraph>
		</>
	);
};

export default TruncateText;

