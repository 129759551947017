import { Switch } from 'antd'
import React from 'react'
interface ItemProps {
	checked: boolean
    label: string
	configKey: string
	changeHandler: (checked: boolean, key: string) => void
}
const Item: React.FC<ItemProps> = ({label, changeHandler, checked, configKey}) => {
	return (
		<div className='preference-content__switch-item'>
			<span className='label'>{label}</span>
			<Switch
				checked={checked}
				onChange={(checked) => changeHandler(checked, configKey)}
			/>
		</div>
	)
}

export default Item
