export const STANDARD_DATE_FORMAT = 'DD/MM/YYYY'
export const STANDARD_TIME_FORMAT = 'HH:mm'
export const STANDARD_DATE_TIME_FORMAT = `${STANDARD_TIME_FORMAT} ${STANDARD_DATE_FORMAT}`
export const TIME_STAMP_FROM = 'timestampFrom'
export const TIME_STAMP_TO = 'timestampTo'
export const CREATED_AT_FROM = 'createdAtFrom'
export const CREATED_AT_TO = 'createdAtTo'
export const SELECT_CONNECTION = 'connection'
export const SELECT_PROFILE = 'username'
export const TAB_PRODUCT = 'TAB_PRODUCT'
export const TAB_INVOICE = 'TAB_INVOICE'
export const TAB_TICKET = 'TAB_TICKET'
export const TAB_TRANSACTION = 'TAB_TRANSACTION'
export const TAB_TRANSPORT = 'TAB_TRANSPORT'
export const TAB_PACKAGES = 'TAB_PACKAGES'
export const USE_TYPE_METHOD_ORDER = 'ORDER'
export const PAYMENT_STATUS_SUCCESS = 'SUCCESS'
export const PAYMENT_STATUS_CANCELLED = 'CANCELLED'
export const PAYMENT_STATUS_FAILURE = 'FAILURE'
export const PAYMENT_STATUS_WAITING_CONFIRM = 'WAITING_CONFIRM'
export const PAYMENT_STATUS_WAITING_PAYMENT = 'WAITING_PAYMENT'
export const PAYMENT_REQUEST_CHANGE = 'order:payment_request:update'
export const PREFERENCE_GENERAL = 'PREFERENCE_GENERAL'
export const PREFERENCE_SHIPMENT = 'PREFERENCE_SHIPMENT'
export const PREFERENCE_BUY = 'PREFERENCE_BUY'
export const PREFERENCE_PAYMENT = 'PREFERENCE_PAYMENT'
export const PREFERENCE_GSA = 'PREFERENCE_GSA'