import {Layout, Spin} from "antd"
import React, {PropsWithChildren, useEffect, useState} from "react"
import SecurityService from "../../Util/SecurityService"
import {Header} from "./Header"
import SideBar from "./Sidebar"
import UserInterface from "../../Interface/UserInterface";

const { Content, Footer } = Layout

interface Props extends PropsWithChildren<any>{
	title: string
}

export const DefaultLayout = (props: Props) => {
	const [loading] = useState(false)
	const [collapsed, setCollapsed] = useState(true)
	const [user] = useState<UserInterface>(SecurityService.getUser())

	useEffect(() => {
		document.title = props.title
	}, [props])

	const toggle = () => {
		setCollapsed(!collapsed)
	}

	const onCollapsed = (collapsed: boolean) => {
		console.log('collapse', collapsed)
		setCollapsed(collapsed)
	}

	return (
		<Layout className={"main-layout"}>
			<SideBar
				collapsed={collapsed}
				onCollapsed={onCollapsed}
			/>
			<Layout>
				<Header
					title={props.title}
					collapsed={collapsed}
					toggle={toggle}
					user={user}
					onCollapsed={onCollapsed}
				/>
				<Content className={"main-content h-100pc"} onClick={() => onCollapsed(true)}>
					<div className={loading ? "loading-container h-100pc" : "main-inner h-100pc"}>
						<Spin tip={"Đang tải..."} spinning={loading} className={"h-100pc"}>
							{props.children}
						</Spin>
					</div>
				</Content>
				<Footer className="footer" onClick={() => onCollapsed(true)}>Powered By Gobiz.</Footer>
			</Layout>
		</Layout>
	)
}

export default DefaultLayout
