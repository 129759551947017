import { Divider } from 'antd'
import lodash from 'lodash'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import TruncateText from '../../../Component/TruncateText'
import OrderDetailInterface, { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import ProviderInterface from '../../../Interface/ProviderInterface'
import { trans } from '../../../resources/localization/Localization'
import { formatMoneyByUnit } from '../../../Util/string'
import Item from './Item'
interface InformationProps {
	details?: OrderDetailInterface
	providers: ProviderInterface[]
	currencyOfProvider: string
	expand: boolean
	loading: boolean
}

export const textFromData = (obj: { [id: string]: any }, key: string, empty: string | Array<any>) => {
	return lodash.isNil(lodash.get(obj, key)) || lodash.isEmpty(lodash.get(obj, key)) ? empty : lodash.get(obj, key)
}

const Information: React.FC<InformationProps> = ({ details, currencyOfProvider, expand, loading }) => {
	const emptyTxt = '---'
	const employeeNote = textFromData(details!, 'employeeNote', emptyTxt)
	const displayAddress = textFromData(details!, 'address.display', emptyTxt)
	const listServices = textFromData(details!, 'services', [])
	const exchangeRateStr = `${formatMoneyByUnit(1, lodash.get(details, 'currency', emptyTxt))}
                          = ${formatMoneyByUnit(lodash.get(details, 'exchangeRate', ''), currencyOfProvider)}`
	const fullNameOfCustomer = textFromData(details!, 'profile.name', emptyTxt)
	const usernameOfCustomer = textFromData(details!, 'profile.username', emptyTxt)
	const depositRateStr = `${
		lodash.isNull(lodash.get(details, 'emdPercent')) ? emptyTxt : lodash.get(details, 'emdPercent', emptyTxt)
	}%`
	const netWeight = lodash.isNil(lodash.get(details, 'netWeight')) ? emptyTxt : `${lodash.get(details, 'netWeight')} kg`
	const packingWeight = lodash.isNil(lodash.get(details, 'packagingWeight'))
		? emptyTxt
		: `${lodash.get(details, 'packagingWeight')} kg`
	const dimensionalWeight = lodash.isNil(lodash.get(details, 'dimensionalWeight'))
		? emptyTxt
		: `${lodash.get(details, 'dimensionalWeight')} kg`
	const chargedWeight = lodash.isNil(lodash.get(details, 'actualWeight'))
		? emptyTxt
		: `${lodash.get(details, 'actualWeight')} kg`

	return (
		<div className={`order-content__text-info pd-12 ${!expand ? 'py-0' : ''}`}>
			{!loading ? <h3>{trans('order.information')}</h3> : <Skeleton width={120} height={22} />}
			{expand && (
				<div className='flex flex-col gap-16'>
					<div className='flex flex-col gap-3'>
						<Item
							label={`${trans('order.receiver')}:`}
							content={
								<span className='text-reg text-nowrap text-500'>
									{lodash.get(details, 'address.fullname', '--')}
								</span>
							}
							loading={loading}
							widthContent={80}
							widthLabel={60}
						/>
						<Item
							label={`${trans('order.phoneNumber')}:`}
							content={lodash.get(details, 'address.phone', '--')}
							widthContent={80}
							widthLabel={60}
							loading={loading}
						/>
						<Item
							label={`${trans('order.address')}:`}
							content={`${lodash.get(details, 'address.detail', '--')}, ${displayAddress}`}
							loading={loading}
							widthLabel={60}
							containerClassContent={'flex-grow-1'}
						/>
					</div>
					<div className='flex flex-grow-0'>
						<div className='flex flex-col gap-3 width-260'>
							<Item
								label={`${trans('orders.customer')}:`}
								content={`${usernameOfCustomer} (${fullNameOfCustomer})`}
								loading={loading}
								widthLabel={60}
								containerClassContent='flex-grow-1'
							/>
							<Item label={`${trans('order.depositRate')}:`} content={depositRateStr}  widthLabel={60}
								containerClassContent='flex-grow-1' loading={loading}/>
							<Item label={`${trans('order.exchangeRate')}:`} content={exchangeRateStr} widthLabel={60}
								containerClassContent='flex-grow-1' loading={loading}/>
							<Item
								label={`${trans('order.services')}:`}
								content={
									listServices.length > 0
										? listServices.map((item: ServiceInterface, idx: number) => {
												let colorService = ''
												if (item.needApprove) {
													if (item.approved === null || item.approved === undefined) {
														colorService = 'services-pending'
													} else if (item.approved === false) {
														colorService = 'services-reject'
													}
												}
												return (
													<span key={idx} className={`mg-r-4 ${colorService}`}>
														{`${item.name} ${idx !== listServices.length - 1 ? '|' : ''}`}{' '}
													</span>
												)
										  })
										: '---'
								}
								loading={loading}
								widthLabel={60}
								containerClassContent='flex-grow-1'
							/>
						</div>
						<Divider type='vertical' className='h-auto border-divider' />
						<div className='flex flex-col flex-grow-1'>
							<Item label={`${trans('order.chargedWeight')}:`} content={chargedWeight} loading={loading} widthLabel={60}
								containerClassContent='flex-grow-1'/>
							<Item label={`${trans('order.netWeight')}:`} content={netWeight} loading={loading} widthLabel={60}
								containerClassContent='flex-grow-1'/>
							<Item label={`${trans('order.dimensionalWeight')}:`} content={dimensionalWeight} loading={loading} widthLabel={60}
								containerClassContent='flex-grow-1'/>
							<Item label={`${trans('order.packingWeight')}:`} content={packingWeight} loading={loading} widthLabel={60}
								containerClassContent='flex-grow-1'/>
							<div className='flex items-center'>
								{!loading ? <span className='text-gray mg-r-3'>{`${trans('order.noteForOrder')}:`}</span> : <Skeleton width={60} height={22} />}
								{!loading ? <span className='text-reg'>
									<TruncateText defaultValue={employeeNote} />
								</span> : <Skeleton containerClassName='flex-grow-1' height={22} />}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Information
