export default interface CustomerCriteria {
	page?: number | null
	size?: number | null
	query?: string | null
	code?: string | null
	mobile?: string | null
	email?: string | null
	createdAtFrom?: string | null
	createdAtTo?: string | null
}

export const CustomerCriteriaDefault = {
	page: 0,
	size: 25,
	query: '',
	code: '',
	mobile: '',
	email: '',
	createdAtFrom: '',
	createdAtTo: '',
}
