import React, {Dispatch, ReactNode, SetStateAction} from "react";
import {Avatar, Col, Image, List, Row, Select, Tooltip} from "antd";
import lodash from "lodash";
import Paragraph from "antd/lib/typography/Paragraph";
import {formatDateTime, formatMoneyByUnit} from "../../Util/string";
import {trans} from "../../resources/localization/Localization";
import SecurityService from "../../Util/SecurityService";
import {
    PERMISSION_PACKAGE_ORDER_UPDATE_PROMOTE,
    PERMISSION_PACKAGE_ORDERS_UPDATE_STATUS,
    PERMISSION_PACKAGE_ORDERS_VIEW_LOG
} from "../../Config/permission";
import {PackageVendorInterface} from "../../Interface/PackageVendorInterface";
import {PackageOrderInterface, PackageStatusEnum} from "../../Interface/PackageOrderInterface";
import PDFIcon from "../../resources/images/icons/pdf-icon.png";
import EMPTY_IMAGE from "../../resources/images/empty-image.jpg";
import ProviderInterface from "../../Interface/ProviderInterface";

type PackageOrderListItemProps = {
    packageItem: PackageOrderInterface | any;
    vendors: PackageVendorInterface[];
    setItemSelectToShowLog: Dispatch<SetStateAction<any>>;
    handlePreviewImg: (itemBillOrder: string) => void;
    promoteStatuses: any[];
    statuses: any[];
    handleUpdateOrderPromoteStatus: (packageOrdCode: string, action: string) => void;
    handleUpdatePackageOrderStatus: (packageOrderCode: string, s: string, selectedItem: PackageOrderInterface) => void;
    renderBill: (status: PackageStatusEnum | string, record: PackageOrderInterface) => ReactNode;
    providers: ProviderInterface[];
}


function PackageOrderListItem(props: PackageOrderListItemProps) {
    const {
        packageItem,
        vendors,
        promoteStatuses,
        statuses,
        setItemSelectToShowLog,
        handlePreviewImg,
        handleUpdateOrderPromoteStatus,
        handleUpdatePackageOrderStatus,
        renderBill, providers,
    } = props;
    const vendor: PackageVendorInterface | undefined = vendors.find((v: PackageVendorInterface) => packageItem.vendor === v.code);
    const services = lodash.get(packageItem, 'packageName');
    const urlExtension = packageItem.billOrder?.split(/[#?]/)[0].split('.').pop().trim();
    const promoteStatusObj = promoteStatuses.find((s: any) => s.code === packageItem.promoteStatus);
    const statusObj = statuses.find((s: any) => s.code === packageItem.status);
    const providerObj = providers.find((s: any) => s.code === packageItem.provider);

    return (
        <List.Item className={'package-order-item'}>
            <Row className={'width-100pc'}
                // align={'middle'}
                 justify={'space-between'}
            >
                <Col span={16}>
                    <Row>
                        <Col xs={22} md={22} lg={22} xl={22} xxl={18}>
                            <div className='flex gap-5 pd-l-5'>
                                <div style={{lineHeight: 4}}>
                                    <Image
                                        className='rad-4'
                                        preview={false}
                                        width={62}
                                        src={lodash.get(vendor, 'avatar', '')}/>
                                </div>
                                <div className='flex flex-col gap-5 pd-l-10'>
                                    <div className='orders-data__info-text flex gap-12 items-center'>
                                        <Paragraph
                                            className='code'
                                            copyable={{
                                                text: lodash.get(packageItem, 'code', '---'),
                                                icon: [
                                                    <Tooltip title={<span>Copy</span>} color='#CD5E77'>
                                                        <i className='far fa-copy line-h-14 w-14'/>
                                                    </Tooltip>,
                                                    <Tooltip title={<span>Copied</span>} color='#CD5E77'>
                                                        <i className='far fa-check fsz-12 line-h-14 w-14'/>
                                                    </Tooltip>,
                                                ],
                                                tooltips: [false, false],
                                            }}>
                                            <span>{`#${lodash.get(packageItem, 'code', '---')}`}</span>
                                        </Paragraph>
                                        <div className='divider'/>
                                        <span
                                            className='white-nowrap'>{formatDateTime(lodash.get(packageItem, 'createdAt', ''))}</span>
                                        <Tooltip title={trans('order.timestamp')} color='#CD5E77'>
                                            <i className='fa-light fa-circle-info pointer'/>
                                        </Tooltip>
                                        <div className='divider'/>
                                        <span className='text text-gray mg-bt-0'>{trans('packageOrders.vendor')}</span>
                                        <span
                                            className='text-reg text-nowrap font-size-12'>{`${lodash.get(vendor, 'name', '---')}`}</span>
                                    </div>
                                    <div className={'orders-data__info-text flex gap-12 item-center'}>
                                        <div className={'items-center'}>
                                            <span className='text text-gray text-nowrap mg-r-3'>{trans('order.services')}:</span>
                                            <span className={`mg-r-4 text text-nowrap`}
                                                  style={{color: '#CD5E77'}}>{`${services}`}</span>
                                        </div>
                                        <div className={'mg-l-12 items-center'}>
                                            <span className='text text-gray '>{trans('order.cash')}:</span>
                                            <span
                                                className='text text-medium mg-l-5'>{formatMoneyByUnit(lodash.get(packageItem, 'totalValue', 0), packageItem.currency)}</span>
                                        </div>
                                        <div className='flex mg-l-12 items-center'>
                                            <span
                                                className='text text-gray font-size-12'>{trans('orders.customer')}:</span>
                                            <span
                                                className='text-reg font-size-12 mg-l-5'>{lodash.get(packageItem, 'customer', '---')}</span>
                                        </div>
                                        <div className='flex mg-l-12 items-center'>
                                            <span
                                                className='text text-gray font-size-12'>{trans('orders.ref-entry')}:</span>
                                            <span
                                                className='text-reg font-size-12 mg-l-5 entry-content'>{packageItem.ref ? lodash.get(packageItem, 'ref', '---') : '---'}</span>
                                            <Paragraph
                                                style={{marginBottom: 0}}
                                                copyable={{
                                                    text: lodash.get(packageItem, 'ref', '---'),
                                                    icon: [
                                                        <Tooltip title={<span>Copy</span>} color='#CD5E77'>
                                                            <i className='far fa-copy line-h-14 w-14'/>
                                                        </Tooltip>,
                                                        <Tooltip title={<span>Copied</span>} color='#CD5E77'>
                                                            <i className='far fa-check fsz-12 line-h-14 w-14'/>
                                                        </Tooltip>,
                                                    ],
                                                    tooltips: [false, false],
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={'flex items-center h-100pc'}>
                                {SecurityService.can(PERMISSION_PACKAGE_ORDERS_VIEW_LOG) && (
                                    <div
                                        className={'txt-size-h7 flex items-center robotoregular text-color-primary cursor-pointer'}
                                        onClick={() => setItemSelectToShowLog({
                                            id: packageItem.id,
                                            packageName: packageItem.packageName,
                                            code: packageItem.code
                                        })}
                                    >
                                        <i className="fa-regular fa-file-lines dpl-il mg-r-4"/>
                                        {trans('packageOrders.log')}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}>
                    <div className='flex flex-col gap-5  items-center'>
                        {urlExtension === 'pdf' ?
                            <div>
                                <a
                                    className={''}
                                    href={packageItem.billOrder}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    <img src={PDFIcon} alt={'pdf'}/>
                                </a>
                            </div>
                            : <Image
                                width={51}
                                src={packageItem.billOrder ? packageItem.billOrder : EMPTY_IMAGE}
                                preview={{
                                    mask: packageItem.billOrder ? <span>
                                        <i className="fa-light fa-eye"/>
                                    </span> : null,
                                    visible: false,
                                }}
                                // preview={false}
                                onClick={() => packageItem.billOrder ? handlePreviewImg(packageItem.billOrder) : null}
                                wrapperClassName={packageItem.billOrder ? `cursor-pointer` : ''}
                                style={{width: '100%', maxHeight: 51}}
                            />
                        }
                    </div>
                </Col>
                <Col span={3}>
                    <div className='flex flex-col gap-5'>
                        <div className={''}>
                            {SecurityService.can(PERMISSION_PACKAGE_ORDER_UPDATE_PROMOTE) ?
                                <div className='orders-data__status width-100pc'>
                                              <span>
                                                  <Select
                                                      className={`package-order-promote-status orders-data__status-select ${
                                                          packageItem.promoteStatus}`}
                                                      onChange={(value: any) => {
                                                          handleUpdateOrderPromoteStatus(packageItem.code, value)
                                                      }}
                                                      value={packageItem.promoteStatus}
                                                  >
                                                      {promoteStatuses.map((s: any, index: number) => {
                                                          return <Select.Option
                                                              className={''}
                                                              key={`status_${packageItem.id}_${index}`}
                                                              value={s.code}>
                                                              <span className={'capitalize'}>{trans(s.name)}</span>
                                                          </Select.Option>
                                                      })}
                                                  </Select>
                                              </span>
                                </div>
                                : <div className='package-order-promote-status orders-data__status width-100pc'>
                                                <span
                                                    className={' font-size-12'}
                                                    style={{
                                                        textAlign: 'center',
                                                        backgroundColor: lodash.get(promoteStatusObj, 'color'),
                                                    }}>
                                                    {trans(promoteStatusObj?.name)}
                                                </span>
                                </div>
                            }
                        </div>
                        <div
                            className='flex flex-row gap-5 text-center width-100pc justify-center items-center'>
                            <Tooltip
                                placement={'left'} title={lodash.get(providerObj, 'name', packageItem.provider)}
                                color={'#CD5E77'} overlayClassName={'rad-20 pd-12 font-size-12'}
                            >
                                <Avatar
                                    style={{alignItems: 'center', minWidth: 16}}
                                    src={lodash.get(providerObj, 'logo', '---')}
                                    shape={'circle'}
                                    size={16}
                                />
                            </Tooltip>
                            <Tooltip
                                // trigger={['click','focus','hover','contextMenu']}
                                placement={'right'} title={lodash.get(packageItem, 'providerAccount')}
                                color={'#CD5E77'} overlayClassName={'rad-20 pd-12 font-size-12'}
                            >
                                <div
                                    className='provide-content text-reg'>{packageItem.providerAccount ? packageItem.providerAccount : '---'}</div>
                            </Tooltip>

                        </div>
                    </div>
                </Col>
                <Col span={3} style={{maxWidth: 150}}>
                    <div className='flex flex-col gap-5 flr pd-r-10'>
                        <div className={'justify-center text-center'}>
                            <span className={''}>
                              {SecurityService.can(PERMISSION_PACKAGE_ORDERS_UPDATE_STATUS) ?
                                  <Select
                                      className={`package-order-promote-status orders-data__status-select ${
                                          packageItem.status === 'PENDING' ? 'orders-data__status-select__pending'
                                              : packageItem.status === 'PAID' ? 'orders-data__status-select__paid'
                                                  : packageItem.status === 'REJECTED' ? 'orders-data__status-select__rejected'
                                                      // : packageItem.status === 'NOTIFIED' ? 'orders-data__status-select__notified'
                                                      : 'orders-data__status-select__waiting'
                                      }`}
                                      onChange={(value: any) => {
                                          handleUpdatePackageOrderStatus(packageItem.code, value, packageItem)
                                      }}
                                      value={packageItem.status}
                                  >
                                      {statuses.map((s: any, index: number) => {
                                          return <Select.Option
                                              key={`status_${packageItem.id}_${index}`}
                                              value={s.code}>
                                              <span className={'capitalize'}>{trans(s.name)}</span>
                                          </Select.Option>
                                      })}
                                  </Select>
                                  : <div className='orders-data__status'>
                                    <span
                                        className={'font-size-12'}
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
                                                ? '#09B2AA'
                                                : lodash.get(statusObj, 'color'),
                                        }}>
                                        {trans(statusObj.name)}
                                    </span>
                                  </div>}
                            </span>
                        </div>
                    </div>
                    <div className={'gap-5 text-right'}>
                        <span>
                            {renderBill(packageItem.status, packageItem)}
                        </span>
                    </div>
                </Col>
            </Row>
            {/*</Col>*/}
        </List.Item>
    )
}

export default PackageOrderListItem;
