import { Image } from "antd"
import React from "react"
import imageError from "../../resources/images/stop-small.png"
import DefaultLayout from "../Layout/Default"

export const AccessDenied = () => {
	return (
		<DefaultLayout title={"Truy cập bị từ chối"}>
			<div className="error-page flex items-center flex-col justify-center">
				<Image src={imageError} alt="403-img" preview={false} />
			</div>
		</DefaultLayout>
	)
}
