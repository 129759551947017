import { memo } from "react";
import { trans } from "../../../resources/localization/Localization";

const promoteStatusMapping = {
    'PROMOTED': trans('packageOrder.promoted-status'),
    'UNPROMOTED': trans('packageOrder.unpromoted-status'),
    'NOTIFIED': trans('packageOrder.notified-status')
}

const ContentPromoteOrder: any = memo(({ item, itemSelectToShowLog }: any) => {
    const valueChange: 'PROMOTED' | 'UNPROMOTED' = item.change?.valueChange?.changedValues[0]?.right;

    return (
        <>
      <span className={'txt-color-black'}>
        {trans('packageOrders.log-updated-of')}
      </span>{' '}
            <span className={'txt-color-black text-medium'}>
        {itemSelectToShowLog?.code}
      </span>{' '}
            <span className={'txt-color-black'}>
        {trans('packageOrders.log-update-to')}:{' '}
      </span>
            <span className={'txt-color-black ' + valueChange}>
        {promoteStatusMapping[valueChange]}
      </span>
        </>
    );
});
export {ContentPromoteOrder}
