import { Avatar } from 'antd';
import React from 'react';
interface BarProps {
	logo?: string;
	code?: string;
	username?: string;
	sizeImg: number;
	divider: string;
	inFilter: boolean;
	className?: string;
}
const ConnectionBar: React.FC<BarProps> = (props) => {
	const { logo, code, username, sizeImg, className, divider, inFilter } = props;
	return (
		<div className={`flex items-center flex-nowrap gap-4 mg-0 ${className ? className : ''}`}>
			<Avatar src={logo} size={sizeImg} shape='circle' />
			{inFilter && <span>{divider}</span>}
			<span>{code}</span>
			<span>{divider}</span>
			<span>{username}</span>
		</div>
	);
};

export default ConnectionBar;

