import React from 'react';
import lodash from 'lodash';
import StatusInterface from '../../Interface/StatusInterface';
interface StatusBarProps {
	statuses: StatusInterface[];
	status?: string;
	colorDefault: string;
}
const StatusBar: React.FC<StatusBarProps> = ({ statuses, status, colorDefault }) => {
	const statusObj = statuses?.find(({ code }: StatusInterface) => code === status);
	const statusName = lodash.isNull(lodash.get(statusObj, 'name')) ? '---' : lodash.get(statusObj, 'name', '---');
	return (
		<span
			className='status text-nowrap'
			style={{
				backgroundColor: lodash.isNull(lodash.get(statusObj, 'color')) ? colorDefault : lodash.get(statusObj, 'color'),
			}}>
			{statusName}
		</span>
	);
};

export default StatusBar;

