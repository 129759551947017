import React from 'react'
import Skeleton from 'react-loading-skeleton'
import OrderDetailInterface from '../../../Interface/OrderDetailInterface'
import { trans } from '../../../resources/localization/Localization'
import FeeBox from './FeeBox'

interface FinanceProps {
	details?: OrderDetailInterface
	currencyOfProvider: string
	expand: boolean
	loading: boolean
}
const Finance = (props: FinanceProps) => {
	const { details, currencyOfProvider, expand, loading } = props
	return (
		<div className={`${expand ? 'mg-bt-24' : 'py-0'} order-content__text-finance pd-12`}>
			<h2 className='mg-0'>{!loading ? trans('order.finance') : <Skeleton width={120} height={22}/>}</h2>
			{expand && <FeeBox currencyOfProvider={currencyOfProvider} details={details} loading={loading}/>}
		</div>
	)
}

export default Finance