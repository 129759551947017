import React, {useEffect, useState} from "react"
import querystring from "query-string"
import localStore from "../../Util/LocalStore"
import lodash from "lodash"
import axios from "axios"
import appConfig from "../../Config/App"
import loadingGif from "../../resources/images/loading.gif"
import errorPng from "../../resources/images/stop.png"
import jwt_decode from "jwt-decode";

export const Authentication = () => {
	const locationHash = window.location.hash
	const params: any = querystring.parse(locationHash.substring(1))
	const [isNotPermission, setIsNotPermission] = useState(false)
	const [msg, setMsg] = useState("Đang kiểm tra xác thực, vui lòng đợi")

	// reset loginSession
	localStore.removeItem("loginSession")
	useEffect(() => {
		document.title = "Xác thực"

		if (lodash.has(params, "access_token")) {
			setMsg("Bạn sẽ được chuyển đến Bảng điều khiển ngay bây giờ")
		} else {
			setIsNotPermission(true)
			setMsg("Không thể đăng nhập")
		}
		const redirectBackUrl = localStore.getItem("redirectBackUrl")
			? localStore.getItem("redirectBackUrl")
			: btoa("/")
		axios({
			url: appConfig.apiUrl + "/profile",
			method: "GET",
			headers: {
				Authorization: "Bearer " + params["access_token"],
			},
		})
			.then((response) => {
				localStore.setJson("loginSession", {
					accessToken: params["access_token"],
					expireAt: params["expire_at"],
				})
				localStore.setJson("loggedUser", response.data)
				localStore.setJson("permissions", lodash.get(jwt_decode(params["access_token"]), 'permissions', []))
				setTimeout(() => {
					// @ts-ignore
					window.location.href = atob(redirectBackUrl)
				}, 1000)
			})
			.catch((error) => {
				localStore.removeItem("loginSession")
				localStore.removeItem("loggedUser")
				setIsNotPermission(true)
				setMsg(lodash.get(error, "response.data.message"))
			})
		// eslint-disable-next-line
	}, [])

	return (
		<div className={"splash-container"}>
			{!isNotPermission ? (
				<div className={"text-center margin-auto"}>
					<img src={loadingGif} alt={""} />
					<p className="splash-text txt-color-black2 mgt25">{msg}</p>
				</div>
			) : (
				<div className={"text-center margin-auto"}>
					<div>
						<img src={errorPng} alt={""} />
					</div>
					<div className="txt-center">
						<p className="splash-text txt-color-black2 mgt20">Không thể xác thực</p>
						<p className="splash-text txt-color-black2 mgt20">Vui lòng liên hệ với Admin</p>
						<div className={"mgt50"}>
							<a href="/" className="splash-text txt-color-blue2 mg-r-20">
								<i className="fas fa-undo-alt mgr5" /> <span>Thử lại</span>
							</a>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
